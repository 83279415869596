import globalAxios from "./global-axios";

export default {
    checkApiControls(){
        return globalAxios.get('api-controles')
    },
    metaDescription(){
        return globalAxios.get('statics/website-metadata')
    },
    getAllCurrencies(){
        return globalAxios.get('lists/currencies')
    }
}