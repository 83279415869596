<template>
  <!-- single supplier page  -->
  <div class="supplier">
    <div
      class="cover text-center mx-auto my-4 text-white" v-if="supplierMSite && supplierMSite.banner_path"
      :style="{ backgroundImage: `url(${supplierMSite.banner_path})` }"
    >
      <div
        class="cover-data p-5 d-flex justify-content-center align-items-center flex-column min-h-350"
      >
        <b-container>
          <div class="cover-title text-white font-weight-bold">
            <b-breadcrumb v-if="supplier">
              <b-breadcrumb-item href="#home">
                <router-link to="/">
                  {{ $t("supplier.home") }}
                </router-link>
              </b-breadcrumb-item>
              <b-breadcrumb-item>
                <router-link to="/suppliers">
                  {{ $t("supplier.suppliers") }}
                </router-link>
              </b-breadcrumb-item>
              <b-breadcrumb-item active>
                {{ supplier.company_name }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-container>
      </div>
    </div>
    <div class="container">

      <b-row v-if="loading">
        <b-col class="mb-2" lg="3" sm="6" v-for="x in 2" :key="x">
          <b-skeleton-img></b-skeleton-img>
          <b-card>
            <b-skeleton
              animation="fade"
              width="60%"
              class="border-none"
            ></b-skeleton>
            <b-skeleton
              animation="fade"
              width="85%"
              class="border-none"
            ></b-skeleton>
          </b-card>
        </b-col>
      </b-row>

      <div class="" v-else>
        <div class="row justify-content-between">
          <!-- import side section data  -->
          <SideSection
            class="col-12 col-lg-4 order-1 order-lg-0"
            :supplier="supplier"
            :supplierMSite="supplierMSite"
          ></SideSection>
          <!-- import other data  -->
          <Article
            class="col-12 col-lg-8 order-0 order-lg-1"
            :data="article"
            :supplier="supplier"
            :supplierMSite="supplierMSite"
          ></Article>
        </div>
        <div
          class="products text-center mt-5 pt-5"
          v-if="supplierProductsLength > 0"
        >
          <h1 class="title">{{ $t("supplier.supplierProducts") }}</h1>
          <div class="row">
            <div
              class="col-12 col-sm-6 col-lg-3"
              v-for="item in supplierProducts"
              :key="item.id"
            >
              <Product :data="item"></Product>
            </div>
          </div>

          <router-link
            class="load-more border-0 rounded-0 py-4 px-5 my-4 mx-0 d-inline-block"
            :to="{ path: `/supplier-products`, query: { supId: supplier.id } }"
          >
            {{ $t("supplier.more") }}
          </router-link>
        </div>
        <div class="d-flex justify-content-center align-items-center" v-else>
          <h3>
            {{ $t("cart.noProducts") }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// single supplier page 
import SideSection from "@/components/pages/supplier/SideSection";
import Article from "@/components/pages/supplier/Article";
import Product from "@/components/pages/supplier/products/Product";
import suppliers from "@/services/suppliers";

export default {
  metaInfo: {
    title: "Supplier page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  components: {
    SideSection,
    Article,
    Product,
  },
  data() {
    return {
      items: [
        {
          text: this.$t("supplier.home"),
          href: "/",
        },
        {
          text: this.$t("supplier.suppliers"),
          href: "#",
        },
        {
          text: this.$t("supplier.company"),
          active: true,
        },
      ],
      id: this.$route.params.id,
      pageId: this.$route.params.id,
      loading: false,
      article: {
        image: require("@/assets/images/supba.png"),
        content: this.$t("supplier.article"),
      },
      product: {
        image: require("@/assets/images/sp.png"),
        name: this.$t("supplier.productName"),
        price: "4.620 KD",
      },
      supplier: null,
      supplierProducts: null,
      supplierProductsLength: null,
      supplierMSite: null,
    };
  },
  methods: {
    /**
     * @vuese
     * this function used to get Supplier data
     */
    getSupplier() {
      this.loading = true;
      let id = this.id;
      suppliers
        .getSupplier(id)
        .then((resp) => {
          this.supplier = resp.data.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * @vuese
     * this function used to get Supplier Products
     */
    getSupplierProducts() {
      let id = this.id;
      suppliers
        .getSupplierProducts(id)
        .then((resp) => {
          this.supplierProducts = resp.data.items.data.slice(0, 20);
          resp.data.items.data.length > 0
            ? (this.supplierProductsLength = resp.data.items.data.length)
            : (this.supplierProductsLength = 0);
        })
    },
     /**
     * @vuese
     * this function used to goto next Page
     */
    nextPage() {
      this.pageId = parseInt(this.pageId) + 1;

      this.$router.replace(`/suppliers/${this.pageId}`);
      this.getSupplier();
      this.getSupplierProducts();

      setTimeout(() => {
        location.reload();
      }, 200);
    },
    /**
     * @vuese
     * this function used to goto prev Page
     */
    prevPage() {
      let prevUrl = this.pageId;
      if (prevUrl > 0) {
        this.pageId = parseInt(this.pageId) - 1;

        this.$router.replace(`/suppliers/${this.pageId}`);
        this.getSupplier();
        this.getSupplierProducts();
        setTimeout(() => {
          location.reload();
        }, 200);
      }
    },
    /**
     * @vuese
     * this function used get supplier MicroSite
     */
    supplierMicroSite() {
      suppliers
        .supplierMicroSite(this.id)
        .then((res) => {
          this.supplierMSite = res.data.items;
        })
    },
  },
  mounted() {
    this.getSupplier();
    this.getSupplierProducts();
    this.supplierMicroSite();
  },
};
</script>
<style lang="scss" scoped>
.supplier {
  .navigation {
    a {
      color: #403a37;
      &:hover {
        color: $main-color;
      }
    }
    .prev span {
      margin-inline-end: 0.2rem;
    }
    .next span {
      margin-inline-start: 0.2rem;
    }
  }
  .products {
    margin-top: 4rem;
  }
  .title {
    color: #312620;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5rem;
  }
  .load-more {
    background: #fff;
    color: #312620;
    font-size: 14px;
    box-shadow: 0px 3px 27px 0px rgb(0 0 0 / 17%);
    &:hover {
      background: $main-color;
      color: #fff;
    }
  }
}

.supplier {
  #bv-modal-example {
    top: 6% !important;
  }
}

.cover {
  background-repeat: no-repeat;
    background-position: 50% 37%;
    background-size: cover;
    background-attachment: fixed;
}
.cover-data {
  background: rgba(0, 0, 0, 0.3);
}
.breadcrumb-item a{
  color: #fff;
}
.min-h-350{
  min-height: 350px;
}
</style>
