export default {
  prev: "المورد السابق",
  next: "المورد التالي",
  home: "الرئيسية",
  suppliers: "الموردين",
  company: "الشركة الكويتية للالبان",
  about: "عن الشركة",
  sections: "الأقسام",
  milk: "لبن",
  article:
    "لأكثر من 59 عامًا ، أصبح اسم شركة الألبان الكويتية مرادفًا لمنتجات الألبان الطازجة عالية الجودة ، المصنعة من الحليب الطازج المنتج يوميًا من مزارع الكويت. منذ إنشائها في عام 1960 ، من خلال مبادرة المزارعين الكويتيين ، تهدف شركة الألبان الكويتية إلى إنشاء عملية إنتاج وتوزيع متطورة تخدم بشكل أساسي الأمن الغذائي في الكويت ، وتحقق نقلة نوعية في الإمكانات الوطنية. تولت شركة الألبان الكويتية هذه المسؤولية بنجاح ، وتطورت لتصبح واحدة من أكبر وأعرق الشركات الصناعية العاملة في مجال الألبان ، على المستوى المحلي ودول مجلس التعاون الخليجي. تدير شركة الألبان الكويتية خطوط إنتاج متنوعة على مساحة 22 ألف متر مربع ، بطاقة إنتاجية 350 طنًا يوميًا ، تسعى لتطوير طاقتها الإنتاجية حتى 550 طنًا يوميًا خلال السنوات القادمة. فريق بحث متطور يعمل على مراقبة الجودة والبحث للتأكد من أن المنتج يلبي جميع المعايير الصحية على مستوى العالم ، ويعمل على هذه الطاقة الإنتاجية الهائلة ، ويطور الأعمال بشكل مستمر من ناحية أخرى. كل هذه الخطوات تتم من خلال مختبر متخصص ومعتمد دوليًا أنشأته شركة الألبان الكويتية خصيصًا لهذا الغرض",
  products: "المنتجات",
  productName: "حليب كامل الدسم",
  more: "اعرض المزيد",
  OurShop: "منتجاتنا",
  Shop: "منتجات المورد",
  Showing: "يتم عرض ",
  of: "من",
  results: "ناتج البحث",
  return_policy: "سياسة الاسترجاع",
  shipping_and_payment_terms: "شروط الشحن و الدفع",
  supplierProducts: "منتجات المورد",
  subject: "الموضوع",
  sendSupplierMessage: "راسل المورد",
  EstimatedPrice:'السعر المحدد',
  by:'من خلال',
  noDataAboutSellingCat:'لايوجد أقسام بيع'
};
