<template>
  <div>
    <!-- register page for buyer   -->
    <!-- register component for buyer   -->
    <RegisterForm />
  </div>
</template>
<script>
import RegisterForm from "@/components/pages/RegisterForm.vue";
export default {
  components: {
    RegisterForm,
  },
  metaInfo: {
    title: "buyer register page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  mounted() {
    if (!this.b2c_status) {
      this.$router.push("/");
    }
  },
};
</script>
