export default {
  itemName: "زيتون يوناني",
  total: "الاجمالي",
  shopping: "سلة المشتريات",
  purchase: "اذهب للشراء",
  noData: "لا يوجد بيانات",
  noDataMatch: "لا يوجد بيانات مطابقة",
  supplier: "اسم المورد",
  priceUnit: "دينار كويتي",
  purchaseCart: "سلة الشراء",
  product: "المنتج",
  price: "السعر",
  quantity: "الكمية",
  UpdateDelivery: "تحديث بيانات التوصيل",
  couponDiscount: "كوبون خصم",
  addCoupon: "اضف كوبون الخصم",
  totalCart: "اجمالي السلة",
  discount: "الخصم",
  delivery: "التوصيل",
  free: "مجاني",
  checkout: "الذهاب للدفع",
  selectOption: "من فضلك قم بالاختيار",
  quoteName: "اسم الطلب",
  message: "ارسل الى المورد",
  submit: "ارسل ",
  cancel: "الغاء",
  noProducts: "لا يوجد منتجات",
  addedToCart: "تم اضافة المنتج للسله",
  addToCart: " اضف المنتج للسلة",
  removedFromCart: "تم حذف المنتج",
  noCartProducts: "لايوجد منتجات فى السلة",
  updateCart: "تم تحديث السلة",
  enableButton: "حذف الكوبون",
  proceAfterDisc: "السعر بعد الخصم",
  currency: "العملة",
  cartCount: "يوجد عدد ",
  cartInCart: "من المنتجات فى السلة",
  cartSubTotal: "السعر الكلى لمنتجات السلة",
  contShopping: "تابع عملية التسوق",
  viewCart: "سلة المشتريات",
  success: "تمت العمليه بنجاح",
  items: "منتج",
  fillData: "من فضلك املأ البيانات",
  next: "التالى",
  addedToWishlist: "تم اضافة المنتج لقائمة المفضلة",
  viewWishlist: "قائمة المفضلة",
  wishlist: "قائمة المفضلة",
  search: "بحث",
  checkFees: "معرفة رسوم الشحن",
  deliveryData: "معلومات التواصل",
  deleiveryFees: "مصاريف الشحن",
  asc: "من الاقل للأعلى ",
  desc: "من الاعلى للأقل",
  sortBy: "تصفيه ب",
  selectPickupAddress: "اختر عنوان استلام للمورد ",
  viewAll: "عرض الكل",
  readMore: "قراءة المذيد",
  readLess: "قراءة أقل",
  add:'اضافه',
  filter:'فلتر',
  standQuantity:'الكميه المطلوبه من المنتج',
  couponExist:'الكود مستخدم بالفعل',
  validCoupons:'الكوبونات المفعله',
  shopNow:'تسوق الان',
  deleiverToAddress:'التوصيل لهذا العنوان',
  choose_your_delivery_method:'قم باختيار طريقة التوصيل'
};
