<template>
  <!-- cart page  -->
  <div class="cart-page">
    <div class="container">
      <!-- include cart comppnent  -->
      <Cart></Cart>
    </div>
  </div>
</template>
<script>

//cart page 
import Cart from "../components/pages/cart/Cart.vue";
export default {
  components: {
    Cart,
  },
  metaInfo: {
    title: "cart page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
