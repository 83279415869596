export default {
  // nav
  home: "الرئيسية",
  suppliers: "الموردين",
  corporat: "تسجيل الشركات",
  buyer: "المشتري",
  contactUs: "اتصل بنا",
  mySupply: "ماي سبلاي",
  allRightsReserved: " جميع الحقوق محفوظة",
  bestDeal: "أفضل الصفقات",
  dailyOffers: "العروض اليومية",
  monthlyOffers: "العروض الخاصه",
  about: "عن الشركة",
  vision: "رؤية الشركة",
  message: "رسالة الشركة",
  myPrinciples: "مبادئ الشركة",
  registerForTheNewsletter: "التسجيل في النشرة الإخبارية",
  beTheFirstToGetExclusiveOffersAndCoupons:
    "كن أول من يحصل على عروض وكوبونات حصرية",
  subscribe: "الإشتراك",
  email: "عنوان بريد الكتروني",
  kuwait: "الكويت",
  cart: "سلة المشتريات",
  KWD: "الدينار الكويتي",
  EGP: "الجنيه المصري",
  showAll: "عرض الكل",
  noData: "لا يوجد بيانات ",
  tilNow: "حتي الان",
  noDataTill: "لا يوجد بيانات حتي الان",
  All: "الكل",
  vendors: "تسجيل الموردين",
  pageNoteFound1: "الصفحة التي تبحث عنها ليست موجودة.",
  you: "انت",
  ok: "أوافق",
  privacyPolicy: "سياسة الخصوصيه",
  termsConditions: "الشروط و الاحكام",
  faq: "الأسئلة الشائعة",
  siteName: "فريق دعم",
  siteSupport: "الرد غالبا يكون خلال ساعة",
  hiThere: "مرحبا",
  helpYou: "كيف يمكننى مساعدتك ؟",
  startCaht: "ابدأ المحادثه",
  navSuppliers: "الشركاء",
  clients: "العملاء",
  consumerCategory: "فئة المستهلك",
  corporateCategory: "فئة الشركة",
  registerThank: "شكرا على انشاء حساب",
  didMean: "هل تقصد:",
  unAuthorized: "غير مصرح لك بالدخول",
  authorized: "مصرح بدخولك",
  cookizHead: "الموافقة على ملفات تعريف الارتباط",
  cookizText:
    "يستخدم هذا الموقع ملفات تعريف الارتباط لمساعدتك في الحصول على تجربة تصفح متفوقة وأكثر صلة على الموقع",
  accept: "موافق",
  decline: "رفض",
  corporate: "الدخول كشركة",
  newSearchText: "جميع مشترياتك بسعر الجملة",
  downloadApp: "قم بتحميل التطبيق",
  ourSuppliers: "موردينا",
  ourClients: "عملائنا",
  consumers: "عملاء التجذئة",
  b2bClients: "عملاء الجملة",
  ourStory: "قصتنا",
  supplierLoggin: "تسجيل الدخول كمورد",
  releventEvents: "الأحداث ذات الصلة",
  verifyEmailMessage: "يرجى التحقق من صندوق البريد الوارد والتحقق من حسابك",
  waitUntilApprove:"يرجى الانتظار حتى يوافق المشرف على التحويل البنكي للاشتراك",
  freeTryEndDate: "ستنتهي محاولتك المجانية عند",
};
