export default {
  // nav
  home: "Home",
  suppliers: "Suppliers",
  corporat: "Corporat",
  buyer: "Buyer",
  contactUs: "Contact us",
  mySupply: "MySupply",
  allRightsReserved: "All Rights Reserved",
  bestDeal: "Best Deals",
  dailyOffers: "Daily Offers",
  monthlyOffers: "Special Offers",
  about: "About us",
  vision: "Vision",
  message: "Message",
  myPrinciples: "My Principles",
  registerForTheNewsletter: "Register for the newsletter",
  beTheFirstToGetExclusiveOffersAndCoupons:
    "Be the first to get exclusive offers and coupons",
  subscribe: "Subscribe",
  email: "Email Address",
  kuwait: "Kuwait",
  cart: "Cart",
  KWD: "KWD",
  EGP: "EGP",
  showAll: "Show all",
  noData: "No Data Available",
  tilNow: "Till Now",
  noDataTill: "No Data Available",
  All: "All",
  vendors: "Clienteles",
  pageNoteFound1: "THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.",
  you: "You",
  ok: "Ok",
  privacyPolicy: "Privacy & Policy",
  termsConditions: "Terms & Conditions",
  faq: "FAQ",
  siteName: "Support",
  siteSupport: "Typical response within one hour",
  hiThere: "Hi there",
  helpYou: "How can I help you?",
  startCaht: "Start Chat",
  navSuppliers: "Partners",
  clients: "Clients",
  consumerCategory: "Consumer Category",
  corporateCategory: "Corporate Category",
  registerThank: "Thanks for Registeration",
  didMean: "Did You Mean:",
  unAuthorized: "You Are Unauthorized",
  authorized: "Authorized",
  cookizHead: "Cookies Consent",
  cookizText:
    "This website use cookies to help you have a superior and more relevant browsing experience on the website.",
  accept: "Accept",
  decline: "Decline",
  corporate: "Corporate",
  newSearchText: "All your purchases at wholesale price",
  downloadApp: "Download our app!",
  ourSuppliers: "Our Suppliers",
  ourClients: "Our Clients",
  consumers: "Consumers",
  b2bClients: "B2b Clients",
  ourStory: "Our Story",
  supplierLoggin: "Supplier Login",
  releventEvents: "Relevent Events",
  verifyEmailMessage: "Please check your mail inbox and verify your account",
  waitUntilApprove:
    "please wait until admin approve the subscription bank transfer",
  freeTryEndDate: "Your free try will end at",
};
