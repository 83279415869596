export default {
  contactUs: "Contact Us",
  writeMessage: "Write your message",
  formName: "Full Name",
  formEmail: "Email",
  formPhone: "Phone",
  formSubject: "Subject",
  formMessage: "Your message",
  formSend: "Send",
  phone: "Phone",
  address: "Address",
};
