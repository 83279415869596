export default {
  itemName: "Greek olives",
  total: "Total",
  shopping: "Shopping",
  purchase: "Checkout",
  noData: "No Data",
  noDataMatch: "No Matching Data",
  supplier: "Supplier name",
  priceUnit: "KWD",
  purchaseCart: "Purchase Cart",
  product: "Product",
  price: "Price",
  quantity: "Quantity",
  UpdateDelivery: "Update delivery Data",
  couponDiscount: "Coupon Discount",
  addCoupon: "Add a discount coupon",
  totalCart: "Total Cart",
  discount: "Discount",
  delivery: "Delivery",
  free: "Free",
  checkout: "Checkout",
  selectOption: "Please select an option",
  quoteName: "Enter Quote Name",
  message: "Message To supplier",
  cancel: "Cancel",
  submit: "Send Request",
  noProducts: "No Products",
  addedToCart: "Product added to cart.",
  addToCart: "Add to cart.",
  removedFromCart: "Product removed from cart",
  noCartProducts: "No Products In cart",
  updateCart: "Cart Updated",
  enableButton: "Remove Coupon",
  proceAfterDisc: "Price After Discount",
  currency: "Currency",
  cartCount: "There are ",
  cartInCart: "in your cart",
  cartSubTotal: "Cart Subtotal",
  contShopping: "Continue Shopping",
  viewCart: "View Cart",
  success: "Success",
  items: "Items",
  fillData: "Please Fill Data",
  next: "Next",
  addedToWishlist: "Product added to Wishlist.",
  viewWishlist: "View Wishlist",
  wishlist: "Wishlist",
  search: "Search",
  checkFees: "Check Delivery Fees",
  deleiveryFees: "Delivery Fees",
  asc:'Low Price To High Price',
  desc:'High Price To Low Price',
  sortBy:'Sort By',
  selectPickupAddress:'Select Pickup Address',
  viewAll:"View All",
  readMore:'Read More',
  readLess:'Read Less',
  add:'Add',
  filter:'Filter By',
  standQuantity:'Quantity of this product',
  couponExist:'Coupon Already Exist',
  validCoupons:'Valid Coupons',
  shopNow:'Shop Now',
  addPromotionTOCart:'Add Promotion To cart',
  deleiverToAddress:'Deliver To This Address',
  // choose_your_delivery_method:"Choose Your Delivery Method"
};
