import register from "./register";
import home from "./home";
import login from "./login";
import profile from "./profile";
import contactUs from "./contactUs";
import cart from "./cart";
import supplier from "./supplier";
import items from "./items";
import singleProduct from "./singleProduct";
import payment from "./payment";

export default {
  register,
  home,
  login,
  profile,
  contactUs,
  cart,
  supplier,
  items,
  singleProduct,
  payment,
};
