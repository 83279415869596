var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container single-product"},[(_vm.myProduct !== null && !_vm.notFound)?_c('div',{},[_c('div',{staticClass:"navigation d-none d-lg-flex justify-content-start align-items-center"},[_c('nav',{attrs:{"aria-label":"breadcrumb "}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.$t("items.home"))+" ")])],1),(_vm.firstCat)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":("/categories/" + (_vm.firstCat.id))}},[_vm._v(" "+_vm._s(_vm.firstCat.title)+" ")])],1):_vm._e(),(_vm.secondCat)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":("/categories/" + (_vm.secondCat.id) + "/variants")}},[_vm._v(" "+_vm._s(_vm.secondCat.title)+" ")])],1):_vm._e(),(_vm.thirdCat)?_c('li',{staticClass:"breadcrumb-item"},[_c('b',[_vm._v(_vm._s(_vm.thirdCat.title))])]):_vm._e()])])]),_c('b-row',{staticClass:"mt-5",attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"slider p-0",attrs:{"cols":"12","md":"4"}},[(_vm.dealType)?_c('div',{staticClass:"ribbon ribbon-top-left",class:{ 'long-rebbon': _vm.dealType.trim().length > 20 }},[_c('span',{class:{ 'long-rebbon-span': _vm.dealType.trim().length > 20 }},[_vm._v(_vm._s(_vm.dealType))])]):(
            !_vm.dealType &&
            _vm.myProduct &&
            _vm.myProduct.buy_get_promotion_running_by_type &&
            _vm.myProduct.buy_get_promotion_running_by_type.promotion &&
            _vm.myProduct.buy_get_promotion_running_by_type.promotion.buy_x
          )?_c('div',{staticClass:"ribbon ribbon-top-left",class:{ 'long-rebbon': _vm.otherDealType > 20 }},[(_vm.myProduct.buy_get_promotion_running_by_type)?_c('span',{class:{ 'long-rebbon-span': _vm.otherDealType > 20 }},[_vm._v(_vm._s(((_vm.$t("profile.buy")) + " " + (_vm.myProduct.buy_get_promotion_running_by_type.promotion.buy_x) + " " + (_vm.$t("profile.get")) + " " + (_vm.myProduct.buy_get_promotion_running_by_type.promotion.get_y) + " ")))]):_vm._e()]):_vm._e(),_c('Slider',{attrs:{"myProduct":_vm.myProduct}})],1),_c('b-col',{staticClass:"product-info",attrs:{"cols":"12","md":"8"}},[_c('ProductInfo',{attrs:{"myProduct":_vm.myProduct,"lastCategory":_vm.lastCategory,"loading":_vm.loading},on:{"filerDynamicInputs":function($event){return _vm.filerDynamicInputs($event)}}})],1)],1),_c('div',{staticClass:"humhum-tabs mt-5"},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":_vm.$t('singleProduct.specsTitle'),"active":""}},[(_vm.myProduct)?_c('Specs',{attrs:{"myProduct":_vm.myProduct}}):_vm._e()],1),_c('b-tab',{attrs:{"title":_vm.$t('singleProduct.ratingHeader')}},[(_vm.myProduct)?_c('Rating',{attrs:{"myProduct":_vm.myProduct}}):_vm._e()],1)],1)],1),_c('section',{staticClass:"my-4"},[_c('div',{staticClass:"humhum-tabs"},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":_vm.$t('singleProduct.otherSuppliers'),"active":""}},[_c('suppliersTab',{attrs:{"thirdCatID":_vm.thirdCat.id}})],1),_c('b-tab',{attrs:{"title":_vm.$t('items.relativeProducts')}},[(_vm.supplierProductsLength > 0)?_c('div',{staticClass:"most-sold text-center related-products"},[_c('div',{},[_c('div',{staticClass:"my-5 py-5"},[(_vm.supplierProductsLength)?_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.supplierProducts.filter(
                        function (product) { return product.product_details_by_type.quantity >= 1; }
                      )),function(item){return _c('div',{key:item.id,staticClass:"p-4"},[_c('Product',{attrs:{"data":item}})],1)}),0):_vm._e()],1)])]):_c('div',{},[_c('h3',[_vm._v(_vm._s(_vm.$t("home.noDataTill")))])])]),_c('b-tab',{attrs:{"title":_vm.$t('singleProduct.relatedOffers')}},[_c('relatedOffers')],1)],1)],1)])],1):(_vm.myProduct == null)?_c('div',{},[_c('LoadingScreen')],1):_vm._e(),(_vm.notFound)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column p-5 notFound"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("profile.notFound"))+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }