import { render, staticRenderFns } from "./NewRelatedOffersTab.vue?vue&type=template&id=3c6b06af&scoped=true&"
import script from "./NewRelatedOffersTab.vue?vue&type=script&lang=js&"
export * from "./NewRelatedOffersTab.vue?vue&type=script&lang=js&"
import style0 from "./NewRelatedOffersTab.vue?vue&type=style&index=0&id=3c6b06af&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c6b06af",
  null
  
)

export default component.exports