export default {
  // Product Info
  category: "فواكه",
  fruitName: "تفاح",
  price: "السعر",
  priceUnit: "دينار كويتي",
  supplier: "المورد",
  weight: "الوزن",
  available: "متوفر بالمخزن",
  bidRequest: "طلب عرض سعر",
  PcsperUnit: "عدد القطع لكل وحدة",
  expiration_date: "تاريخ انتهاء الصلاحية",
  warantyType: "الضمان",
  min_order_quantity: "أقل كمية للطلب",
  return_time: "مدة الاسترجاع",
  delivery_time: "مدة التوصيل",
  addFavorites: "اضف للمفضلة",
  addPurchase: "اضف لقوائم المشتريات",
  addCart: "اضف للسلة",
  sharing: "مشاركة",
  // Specs
  specsTitle: " مواصفات المنتج",
  specsHeader: "معلومات المنتج",
  specsDescription:
    "يمتاز التفاح بمحتواه العالي من مضادات الأكسدة، الفلافونويدات، وبعض أنواع الفيتامينات، ما جعله مصدر غذائي مفيد للغاية.",
  origin: "بلد المنشأ",
  originData: "امريكي",
  importer: "المستورد",
  supplierName: "اسم المورد",
  country: "البلد",
  kuwait: "الكويت",
  deliveryTime: "وقت التوصيل",
  deliveryTimeData: "خلال ٢٤ ساعة",
  deliveryBy: "توصيل بواسطة",
  deliveryByData: "المورد (مجانا داخل الكويت)",
  // Rating
  ratingTitle: "تقييم",
  ratingHeader: "تقييمات",
  ratingDescription: "لن يتم نشر عنوان بريدك الإلكتروني. الحقول المطلوبة .",
  ratingHint: "كن أول مراجع لمركز اللحوم",
  ratingLabel: "تقييمك*",
  reviewLabel: "رأيك*",
  reviewInput: "أضف تعليقك...",
  nameInput: "اسم عرض السعر",
  emailInput: "بريدك الإلكتروني*",
  checkInput: "احفظ اسمي والبريد الإلكتروني في هذا المتصفح في المرة التالية.*",
  loginFirst: "يجب عليك تسجيل الدخول اولا",
  registerNow: "  اذا لم يكن لديك اى حساب برجاء انشاء حساب جديد",
  productInCart: "موجود بقائمة المفضلة",
  outOfStock: "عذرا , لقد نفذت الكمية",
  noReviews: "لايوجد تقييمات ",
  noAvailQuant:'الكميه المتوفره اقل من اقل كميه للاضافه',
  serchingResult:'نتيجة بحثك عن :',
  orderTrack2:'لتتبع طلبك يجب تسجيل الدخول برقم الهاتف الذى قمت باجراء الطلب من خلاله',
  addcheckedProductToCart:'اضافة العناصر المختارة الى السله',
  removecheckedProductToCart:'حذف العناصر المختارة من القائمه',
  exportSelectedOrders:'تصدير الطلبات المختاره',
  brand:'العلامة التجارية',
  replaceRFQProduct:'هذا المنتج موجود بالفعل فى السله , هل تريد استبداله ؟',
  returnGiftOrder:'إذا قمت بهذا الإجراء ، فسيعود منتج الهدية أيضًا',
  otherSuppliers:'موردون اخرون',
  relatedOffers:'عروض ذات صلة',
  getOffer:'أحصل على العرض',
  addToWithoutOffer:'اضف للسلة بدون العرض'
};
