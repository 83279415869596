export default {
  // category: "Fruit",
  fruit: "Apple",
  description: "Studies suggest that apples may be a healthy foods of high nutritional value, rich in important fiber for the body, antioxidants and flavonoid, making it one of the best foods for the heart.",
  typesSelect: "Choose Types",
  weightSelect: "Choose Weight",
  facilitySelect: "Choose Facility",
  optionSelect: "Please select an option",
  prev: "Prev Product",
  next: "Next Product",
  home: "Home",
  // Products Table
  products: "Products",
  type: "#",
  item: "Item",
  image: "Image",
  supplier: "Supplier",
  unit: "Unit",
  price: "Price",
  quantity: "Quantity",
  addTo: "Add To",
  addToCart: "Add To Cart",
  kg: "Kg",
  // Products Most Sold
  new: "New",
  relativeProducts:'Related Products',
  addToFavourite:'Add To Favourite',
  addedToFavourite:'Already in favourite',
  addStandingOrders:'Add To Standing Orders',
  standingOrders:'Standing Orders',
  addNew:'Add New',
  time:'order frequency',
  edit:'Edit',
  editGroup:'Edit Group',
  standingOrderProducts:'Standing Order Products',
  deleteGroup:'Delete Group',
  sureDelete:'Are you Sure to Delete ',
  remove:'Remove',
  addAllToCart:'Add All To Cart',
  removeGroup:'Remove This Group',
  backToStrandingList:'Back To Standing Orders',
  advertise:'Ad',
  basketProducts:'Basket Products',
  relatedBaskets:'Related Baskets',
  category:"Category",
  subCategory:"Sub Category",
  removeFromWishlist:'Remove From Favorite',
  offers:'Offers',
  sponsoredAds:'Sponsored Ad',
  offerName:"Offer",
  certifications:"certifications",
  newModal1:"You have to register as a business to view these fields.",
  newModal2:"A business account allows you to compare all available products to find the best deals.",
  newModalButton:"Register for a business account"
};
