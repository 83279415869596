import Vue from "vue";
import auth from "@/services/auth";
Vue.mixin({
  computed: {
    getDir() {
      return document.documentElement.dir;
    },
    isLoggined() {
      if (localStorage.getItem("userInfo")) {
        return true;
      } else {
        return false;
      }
    },
    mainDoamin() {
      return process.env.VUE_APP_DOMAIN_NAME;
    },
    mainAppApiLink() {
      return process.env.VUE_APP_AXIOS_LINK;
    },
    supplierDomain() {
      return process.env.VUE_APP_SUPPLIER;
    },
    massgeOfVerify() {
      return localStorage.getItem("massege") ? localStorage.getItem("massege") : null;
    },
    userInfo() {
      return this.$store.getters.userInfo ? this.$store.getters.userInfo : localStorage.getItem('userInfo');
    },
    buyerUserData() {
      return this.$store.getters.buyerUserData;
    },
    currency() {
      return localStorage.getItem("currency");
    },
    socialLogin() {
      return localStorage.getItem("provider");
    },
    userCountries() {
      return localStorage.getItem("countries");
    },
    selectedCountry() {
      return localStorage.getItem("country");
    },
    countryByIP() {
      return localStorage.getItem("countryByIP");
    },
    currentLang() {
      return localStorage.getItem("lang");
    },
    cartAvailable() {
      return localStorage.getItem("cartAvailable");
    },
    RfqAvailable() {
      return localStorage.getItem("RfqAvailable");
    },
    storedSiteLogo(){
      return localStorage.getItem('siteLogo')
    },
    storedAdminLogo(){
      return localStorage.getItem('adminLogo')
    },
    storedSiteTitle(){
      return localStorage.getItem('siteTitle')
    },
    logoEnv(){
      return this.storedSiteLogo ? process.env.VUE_APP_FOR_IMAGES + 'storage/site_settings_image/' + this.storedSiteLogo : null
    },
    adminLogo(){
      return this.storedAdminLogo ? process.env.VUE_APP_FOR_IMAGES + 'storage/site_settings_image/' + this.storedAdminLogo : null
    },
    chatImageBaseUrl(){
      return this.storedAdminLogo ? process.env.VUE_APP_FOR_IMAGES + 'storage/client_supplier_chat/'  : null
    },
    ReturnImageBaseUrl(){
      return process.env.VUE_APP_FOR_IMAGES ? process.env.VUE_APP_FOR_IMAGES + 'storage/return-reason/'  : null
    },
    arabicAvailable(){
      return localStorage.getItem('arabic')
    },

    // site  api controls

    add_to_cart() {
      return this.$store.state.apiControls.add_to_cart;
    },
    RFQ() {
      return this.$store.state.apiControls.RFQ;
    },
    supplier_messages() {
      return this.$store.state.apiControls.supplier_messages;
    },
    b2b_buyer_registration() {
      return this.$store.state.apiControls.b2b_buyer_registration;
    },
    social_login_facebook() {
      return this.$store.state.apiControls.social_login_facebook;
    },
    standing_order() {
      return this.$store.state.apiControls.standing_order;
    },
    product_table_ads() {
      return this.$store.state.apiControls.product_table_ads;
    },
    favourite() {
      return this.$store.state.apiControls.favourite;
    },
    bank_transfer_payment() {
      return this.$store.state.apiControls.bank_transfer_payment;
    },
    wallet_payment() {
      return this.$store.state.apiControls.wallet_payment;
    },
    banner_ads() {
      return this.$store.state.apiControls.banner_ads;
    },
    main_banner() {
      return this.$store.state.apiControls.main_banner;
    },
    social_login_apple() {
      return this.$store.state.apiControls.social_login_apple;
    },
    online_payment() {
      return this.$store.state.apiControls.online_payment;
    },
    guest_checkout() {
      return this.$store.state.apiControls.guest_checkout;
    },
    cash_on_delivery_payment() {
      return this.$store.state.apiControls.cash_on_delivery_payment;
    },
    supplier_registration() {
      return this.$store.state.apiControls.supplier_registration;
    },
    social_login_microsoft() {
      return this.$store.state.apiControls.social_login_microsoft;
    },
    wallet_withdrawl() {
      return this.$store.state.apiControls.wallet_withdrawl;
    },
    social_login_google() {
      return this.$store.state.apiControls.social_login_google;
    },
    special_offers() {
      return localStorage.getItem("special_offers") === 'available' ? true : null;
    },
     storedSiteLoader(){
      return localStorage.getItem('siteLoader')
    },
    loaderLogo(){
      return this.storedSiteLoader ? process.env.VUE_APP_FOR_IMAGES + 'storage/site_settings_image/' + this.storedSiteLoader : null
    },
     storedSiteWebsiteDownload(){
      return localStorage.getItem('websiteDownload')
    },
    downloadSectionImage(){
      return this.storedSiteWebsiteDownload ? process.env.VUE_APP_FOR_IMAGES + 'storage/site_settings_image/' + this.storedSiteWebsiteDownload : null
    },
    smsStatus(){
      return localStorage.getItem('sms_status') === 'available' ? true : null;
    },
    allCurrncies(){
      return JSON.parse(localStorage.getItem('allCurrncies'))
    },
    appDefaultCurrency(){
      return JSON.parse(localStorage.getItem('appDefaultCurrency'))
    },
    appDefaultCurrencyCode(){
      return JSON.parse(localStorage.getItem('appDefaultCurrencyCode'))
    },
    decimal_digits(){
      return localStorage.getItem('decimal_digits')
    },
    b2b_status(){
      return this.$store.state.guestSettings.b2b_status;
    },
    b2c_status(){
      return this.$store.state.guestSettings.b2c_status;
    },
    b2b_guest(){
      return this.$store.state.guestSettings.b2b_guest;
    },
    userIsDoctor(){
      return localStorage.getItem('userIsDoctor')
    },
    Doctor_subscriptions_status(){
      return this.$store.state.guestSettings.Doctor_subscriptions_status;
    },
    buyerSubscriped(){
      return localStorage.getItem('buyerSubscriped')
    },
    B2b_subscription_status(){
      return this.$store.state.guestSettings.B2b_subscription_status;
    }
  },
  methods: {
    logout() {
      auth.logout();
      localStorage.removeItem("userInfo");
      localStorage.removeItem("newUserInfo");
      localStorage.removeItem("buyerUserData");
      localStorage.removeItem("provider");
      localStorage.removeItem("massege");
      localStorage.removeItem("add_to_cart");
      localStorage.removeItem("RFQ");
      sessionStorage.removeItem('ODID')
      this.$router.replace("/");
      setTimeout(() => {
        location.reload();
      }, 150);
    },
    sucessMsg(text, icon = "success") {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: icon,
        title: text,
      });
    },
    errMsg(text, icon = "error") {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        icon: icon,
        title: text,
      });
    },
  },
});
