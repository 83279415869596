export default {
  // Product Info
  category: "Fruit",
  fruitName: "Apple",
  price: "Price",
  priceUnit: "KWD",
  supplier: "Supplier",
  weight: "Weight",
  available: "Available",
  bidRequest: "Request For quotation",
  PcsperUnit: "Pcs per Unit",
  expiration_date: "Expiration Date",
  warantyType: "Waranty Type",
  min_order_quantity: "Minimum Order Quantity",
  return_time: "Return Time",
  delivery_time: "Delivery Time",
  addFavorites: "Add to favorites",
  addPurchase: "Add to purchase lists",
  addCart: "Add to Cart",
  sharing: "Sharing",
  // Specs
  specsTitle: "Product Specifications",
  specsHeader: "Info",
  specsDescription:
    "Apple features its high content of antioxidants, flavonoid, and some types of vitamins, making it a very useful diet.",
  origin: "Origin",
  originData: "American",
  importer: "Importer",
  supplierName: "Supplier Name",
  country: "Country",
  kuwait: "Kuwait",
  deliveryTime: "Delivery Time",
  deliveryTimeData: "Within 24 hours",
  deliveryBy: "Delivery By",
  deliveryByData: "Supplier (free inside Kuwait)",
  // Rating
  ratingTitle: "Rating",
  ratingHeader: "Reviews",
  ratingDescription:
    "Your email address will not be published. Required fields are marked.",
  ratingHint: "Be the first to review “MEAT CENTER”",
  ratingLabel: "Your Rating*",
  reviewLabel: "Your Review*",
  reviewInput: "Add Your Comment...",
  nameInput: "Quote Name",
  emailInput: "Your Email*",
  checkInput:
    "Save My Name And Email In This Browser For The Next Time I Comment.*",
  loginFirst: "You Must Login First!",
  registerNow: "If You don't have account , register First ",
  productInCart: "Product In Favorite",
  outOfStock: "Out Of Stock",
  noReviews:'No Reviews',
  noAvailQuant:'Available Quantity less than the minimum number for order',
  serchingResult:'You are searching for : ',
  orderTrack2:'To track your order you must register with the same phone number you made the order with.',
  addcheckedProductToCart:'Add Selected To Cart',
  removecheckedProductToCart:'Remove Selected From Group',
  exportSelectedOrders:'Export Selected Orders',
  brand:'Brand',
  replaceRFQProduct:'The item is already in the cart, do you want to replace it',
  returnGiftOrder:'if you do this action,prize product will return also',
  otherSuppliers:'Other Suppliers',
  relatedOffers:'Related Promotions',
  getOffer:"Get Offer",
  addToWithoutOffer : 'Add To Cart Without Offer'
};
