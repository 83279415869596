export default {
  payment: "الدفع",
  deliveryData: "بيانات التوصيل",
  backToCart: " العودة للسلة",
  firstName: "الاسم الاول",
  lastName: "الاسم الاخير",
  
  optional: "اختياري",
  country: "البلد",
  address: "عنوان الشارع",
  governorate: "المحافظة",
  city: "المدينة",
  postalCode: "الرمز البريدي",
  phoneNumber: "رقم الهاتف",
  email: "البريد الاليكتروني",
  deliverySameAddress: "التوصيل لنفس العنوان؟",
  paymentData: "بيانات الدفع",
  notes: "ملاحظات",
  total: "الاجمالي",
  discount: "الخصم",
  freeDelivery: "توصيل مجاني",
  priceUnit: "دينار كويتي",
  bankTransfer: "تحويل بنكي",
  paymentByBank: "الدفع عن طريق البنك",
  paymentWhenReceiving: "الدفع عند الاستلام",
  requestReceipt: "الدفع مع استلام الطلب",
  onlinePayment: "التحويل لحسابى",
  accept: "انا موافق على",
  termsAndConditions: "الشروط والأحكام",
  checkout: "الدفع",
  delivery: "توصيل",
  pickup: "اختر موقع استلام",
  selectExist: "اختر من العناوين المتاحه",
  country_code: "رمز الدولة",
  chooseCurrency: "اختر عملة الشراء",
  waitPurchace1: "شكرا لاستكمال طلبك",
  waitPurchace2: "سوف يتم ارسال تفاصيل طلبك على بريدك الالكترونى / رسالة قصيرة",
  congrats: "مبروك!",
  orderTrack: "تتبع طلبك",
  fail: "حدث خطأ",
  fail1: "لقد حدث خطأ ما",
  selectOption: "من فضلك قم بالاختيار",
  orderNumber: "رقم الطلب",
  orderDate: "التاريخ",
  paymentMethod: "وسيلة الدفع",
  loadingParagraph:
    "جار تحميل طريقة الدفع , برجاء الانتظار . قد يستغرق هذا مايصل الى ٨ ثوان",
  orderPlaced: "تم تسجيل طلبك بنجاح",
  checkCachResult:
    "سيتم تحويلك تلقائيا الى طلبك , برجاء الانتظار قد يستغرق هذا مايصل الى ٨ ثوان",
  checkBankResult:
    "سيتم تحويلك تلقائيا الى صفحة تأكيد عملية التحويل البنكى , برجاء الانتظار قد يستغرق هذا مايصل الى ٨ ثوان",
  couponValid: "الكوبون صحيح",
  couponNotValid: "الكوبون غير صحيح",
  uploadImage: "قم بتحميل الصورة",
  paymentStatus: "حالة الدفع",
  bankImageDownload: "تحميل ملف التحويل",
  bankTransImage: "صورة التحويل البنكى",
  bankComment: "ملاحظات التحويل البنكى",
  orderStatus: "حالة الطلب",
  cancelOrder: "الغاء",
  postalError: "يجب ان يكون ٦ ارقام",
  CountryRequired: "حقل الدوله الزامى ",
  RegionRequired: "حقل المحافظه الزامى ",
  CityRequired: "حقل المدينه الزامى ",
  AddressRequired: "حقل العنوان الزامى ",
  choosePaymentMethod:'اختر طريقة الدفع',
  haveCoupon:'هل تمتلك كوبون ؟',
  Apply:'تطبيق',
  search:'بحث',
  resetDate:'حذف المدة',
  coupon:'الكوبون'
};
