<template>
  <!-- terms and conditions page  -->
  <div>
    <div class="container">
      <div class="text-center mt-3" v-if="terms">
        <!-- <h1 v-if="terms.title">
          {{terms.title}}
        </h1> -->
        <div class="lead my-4" v-if="terms.description" v-html="terms.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/services/auth";
export default {
  metaInfo: {
    title: "terms and conditions page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  data() {
    return {
      terms: null,
    };
  },
  methods: {
    /**
     * @vuese
     * this function used to get termsAndCondations
     */
    termsAndCondations() {
      auth
        .termsAndCondations()
        .then((res) => {
          this.terms = res.data.items
        })
    },
  },
  mounted(){
    this.termsAndCondations()
  }
};
</script>

