export default {
  createCustomerAccount: "Register Retail Custom Account",
  mainInformation: "main information",
  alreadyHaveAccount: "Already have account",
  firstName: "first name",
  lastName: "last name",
  email: "email",
  password: "password",
  confirmPassword: "confirm password",
  countryCode: "country code",
  phone: "phone number",
  chooseOneOfTheWays: "Choose one of the ways to activate your account",
  PleaseReview: "Please review and agree to the",
  termsConditions: "Terms and Conditions",
  toCompleteTheRegistration: "to complete the registration process",
  subscribeTheNewsletter: "subscribe to the newsletter",
  submit: "Submit",
  unableRegister:
    " If you are unable to register, please contact us for assistance",
  registrationCompany:
    "registration for the accounts of restaurants, hotels and companies wholesale purchase",
  companyName: "company name",
  arabicCompanyName: "Arabic Company Name",
  englishCompanyName: "English Company Name",
  jobTitle: "job title",
  pleaseVerification: "Please Verify Your Account",
  codeSent: "The verification code has been sent to your phone",
  verificationAccount: "Verify Account",
  verification: "Verify",
  notArrive: "Did Not Receive ? Resend",
  openEmail: "Please open your email to verify your account to can login",
  otpVerify:'You must verify your Phone number by send otp',
  emailVerify:'Verify The Email',
  haveNotAccount: "Don't have account ? Create new account",
  resetPassword: "Type New Password",
  passwordConfirmation: "Password Confirmation",
  save: "Save",
  department:'Department',
  selectDept:'Select Department',
  selectPrefixes:'Select Title',
  newPolicy:'By Submitting, you agree to the ',
  verify:'Verify',
  wholeSale:'Whole Sale Clients',
  passCheck1:'The password must be at least 8 characters.',
  passCheck2:'At least contains one char (Upper Case, Lower Case, Special Char, and Number)',
  resendCode:'Resend Verification Code'
};
