var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'dynamic-form-holder': _vm.cartPage }},[_c('div',{staticClass:"newForm-holder"},_vm._l((_vm.dynamicInputs),function(input,index){return _c('div',{key:index,staticClass:"newForm"},[_c('b-form-group',[(input.dynamic_input.type == 'checkbox')?_c('div',[_c('label',{attrs:{"for":input.dynamic_input.uuid}},[_vm._v(_vm._s(_vm.$i18n.locale == "en" ? input.dynamic_input.title_en : input.dynamic_input.title_ar))]),_c('p',[_vm._v(_vm._s(_vm.form[input.dynamic_input.uuid] == true ? _vm.$t('yes') : _vm.$t('no')))])]):_vm._e(),(input.dynamic_input.type == 'text')?_c('div',[_c('label',{attrs:{"for":input.dynamic_input.uuid}},[_vm._v(_vm._s(_vm.$i18n.locale == "en" ? input.dynamic_input.title_en : input.dynamic_input.title_ar))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.form[input.dynamic_input.uuid])}})]):_vm._e(),(input.dynamic_input.type == 'select')?_c('div',[_c('label',{attrs:{"for":input.dynamic_input.uuid}},[_vm._v(_vm._s(_vm.$i18n.locale == "en" ? input.dynamic_input.title_en : input.dynamic_input.title_ar))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.form[input.dynamic_input.uuid])}})]):_vm._e(),(input.dynamic_input.type == 'file')?_c('div',[_c('div',{staticClass:"row"},[_c('div',{class:{
                'col-md-6 col-sm-12': input.dynamic_input.values,
                'col-12': !input.dynamic_input.values,
              }},[_c('label',{attrs:{"for":input.dynamic_input.uuid}},[_vm._v(_vm._s(_vm.$i18n.locale == "en" ? input.dynamic_input.title_en : input.dynamic_input.title_ar))]),(
                  _vm.form[input.dynamic_input.uuid] &&
                  _vm.filePreviews[input.dynamic_input.uuid]
                )?_c('div',[(_vm.isImage(_vm.filePreviews[input.dynamic_input.uuid]))?_c('div',{staticClass:"d-flex flex-column"},[_c('img',{staticClass:"img-preview border-main",attrs:{"src":_vm.filePreviews[input.dynamic_input.uuid]}}),_c('b-button',{staticClass:"w-fit-content mt-2 btn border-main",on:{"click":function($event){return _vm.downloadWithAxios(
                        input.value_path
                      )}}},[_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1):_c('div',[_c('b-button',{staticClass:"w-fit-content mt-2 btn border-main",on:{"click":function($event){return _vm.downloadFile(input.value_path)}}},[_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1)]):_vm._e()])])]):_vm._e()])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }