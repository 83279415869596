export default {
  // category: "فواكه",
  fruit: "تفاح ",
  description:
    "تشير الدراسات إلى أن التفاح قد يكون أحد الأطعمة الصحية ذات القيمة الغذائية العالية، فهو غنية بالألياف الهامة للجسم ومضادات الأكسدة والفلافونويد، مما يجعله واحدا من أفضل الأطعمة للقلب.",
  typesSelect: "اختار الانواع",
  weightSelect: "اختار الوزن",
  facilitySelect: "اختار المنشا",
  optionSelect: "يرجى تحديد خيار",
  prev: "المنتج السابق",
  next: "المنتج التالي",
  home: "الرئيسية",
  // Products Table
  products: "المنتجات",
  type: "#",
  item: "الصنف",
  image: "الصورة",
  supplier: "المورد",
  unit: "الوحدة",
  price: "السعر",
  quantity: "الكمية",
  addTo: "اضف الي",
  addToCart: "اضف للسلة",
  kg: "كيلو",
  // Products Most Sold
  new: "جديد",
  relativeProducts: "منتجات ذات الصلة",
  addToFavourite: "اضف الى المفضلة",
  addedToFavourite: "موجودة بالفعل فى المفضلة",
  addStandingOrders: "اضافه لقائمة الانتظار",
  standingOrders: "قائمة الانتظار",
  addNew:'اضافه',
  time:'المده',
  edit:'تعديل',
  editGroup:'تعديل مجموعه',
  standingOrderProducts:'منتجات القائمه',
  deleteGroup:'حذف مجموعه',
  sureDelete:'هل انت متأكد من حذف',
  remove:'حذف',
  addAllToCart:'اضافة الكل لسلة الشراء',
  removeGroup:'حذف الكل',
  backToStrandingList:'العوده للقوائم المتاحه',
  advertise:'اعلان',
  basketProducts:'منتجات السلة',
  relatedBaskets:'السلات ذات الصلة',
  category:"قسم",
  subCategory:"قسم فرعى",
  removeFromWishlist:'حذف من قائمةالمفضلة',
  offers:'العروض',
  sponsoredAds:'اعلان ممول',
  offerName:'عرض',
  certifications:"شهادات التوثيق",
  newModal1: "يجب عليك التسجيل كعمل تجاري لعرض هذه الحقول.",
  newModal2: "يتيح لك حساب الأعمال مقارنة جميع المنتجات المتاحة للعثور على أفضل الصفقات.",
  newModalButton: "سجل للحصول على حساب تجاري",
};
