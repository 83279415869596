var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"documentsB2b",class:_vm.$i18n.locale},[_c('div',{staticClass:"all-documents"},[_c('div',{staticClass:"header d-flex justify-content-between"},[_c('h5',[_vm._v(_vm._s(_vm.$t("profile.companyDocuments")))])]),(
        _vm.allDocFiles.ccl !== '' ||
        _vm.allDocFiles.auth_civil_copy !== '' ||
        _vm.allDocFiles.ccs !== '' ||
        _vm.allDocFiles.rmcm !== ''
      )?_c('form',{staticClass:"buissnessinfo mb-5",on:{"submit":function($event){$event.preventDefault();return _vm.buissnessinfoUpload.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[(_vm.allDocFiles.ccl !== '')?_c('div',{staticClass:"col-md-3 col-sm-12"},[_c('div',{staticClass:"form-input mb-4 new-style",class:{
              'border-main-force':
                _vm.buisnessData && _vm.buisnessData.ccl_path !== null,
            }},[_c('label',{attrs:{"for":"CommercialLicense"}},[_vm._v(" "+_vm._s(_vm.$t("profile.commercialLicense"))+" "),(_vm.allDocFiles.ccl_required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.buisnessData)?_c('div',{staticClass:"row justify-content-center align-content-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ccl_pathType),expression:"!ccl_pathType"}]},[_c('b-form-group',[_c('b-form-file',{attrs:{"size":"lg","id":"CommercialLicense","placeholder":_vm.$t('profile.filePlaceHolder'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.buissnessinfo.ccl),callback:function ($$v) {_vm.$set(_vm.buissnessinfo, "ccl", $$v)},expression:"buissnessinfo.ccl"}})],1)],1),_c('div',{},[(_vm.buisnessData)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[(_vm.ccl_pathType === 'image' && _vm.ccl_pathType !== null)?_c('div',{},[(_vm.buisnessData.ccl_path)?_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.ccl_path",modifiers:{"ccl_path":true}}],attrs:{"src":_vm.buisnessData.ccl_path,"alt":"moa-image"}}):_vm._e(),_c('b-modal',{attrs:{"id":"ccl_path","title":_vm.$t('profile.commercialLicense')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
            var close = ref.close;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.$t("profile.commercialLicense"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" x ")])]}},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-block",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadImage(
                              _vm.buisnessData.ccl_path,
                              (_vm.extension = _vm.buisnessData.ccl_path
                                .split('.')
                                .pop()),
                              _vm.$t('profile.commercialLicense')
                            )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])]},proxy:true}],null,false,4127248142)},[[(_vm.buisnessData.ccl_path)?_c('img',{staticClass:"img-fluid w-100",attrs:{"src":_vm.buisnessData.ccl_path,"alt":"moa-image"}}):_vm._e()]],2)],1):(
                      _vm.ccl_pathType === 'document' && _vm.ccl_pathType !== null
                    )?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('a',{attrs:{"href":_vm.buisnessData.ccl_path,"target":"_blank"}},[_c('canvas',{staticClass:"custom-canvas",attrs:{"id":"ccl_pathType-canvas"}})]),_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadItem(
                          _vm.buisnessData.ccl_path,
                          (_vm.extension = _vm.buisnessData.ccl_path
                            .split('.')
                            .pop()),
                          _vm.$t('profile.commercialLicense')
                        )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1):_vm._e()]):_vm._e()])]):_vm._e(),_vm._l((_vm.uploadErrors.ccl),function(error,index){return _c('div',{key:index,staticClass:"error text-start"},[_vm._v(" "+_vm._s(error)+" ")])}),(_vm.ccl_pathType)?_c('div',{staticClass:"edit-address",attrs:{"role":"button"},on:{"click":function($event){return _vm.editSliderAdress('CommercialLicense')}}},[_c('font-awesome-icon',{staticClass:"main-color",attrs:{"icon":"fa-solid fa-pen","size":"xl"}})],1):_vm._e()],2)]):_vm._e(),(_vm.allDocFiles.auth_civil_copy !== '')?_c('div',{staticClass:"col-md-3 col-sm-12"},[_c('div',{staticClass:"form-input mb-4 new-style",class:{
              'border-main-force':
                _vm.buisnessData && _vm.buisnessData.auth_civil_copy_path !== null,
            }},[_c('label',{attrs:{"for":"signatureAccreditation"}},[_vm._v(" "+_vm._s(_vm.$t("profile.signatureAccreditation"))+" "),(_vm.allDocFiles.auth_civil_copy_required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"row justify-content-center align-content-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.auth_civil_copyType),expression:"!auth_civil_copyType"}]},[_c('b-form-group',[_c('b-form-file',{attrs:{"id":"CommissionerCard","size":"lg","placeholder":_vm.$t('profile.filePlaceHolder'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.buissnessinfo.auth_civil_copy),callback:function ($$v) {_vm.$set(_vm.buissnessinfo, "auth_civil_copy", $$v)},expression:"buissnessinfo.auth_civil_copy"}})],1)],1),_c('div',{},[(_vm.buisnessData)?_c('div',{staticClass:"d-flex justify-content-center align-content-center"},[(
                      _vm.auth_civil_copyType === 'image' &&
                      _vm.auth_civil_copyType !== null
                    )?_c('div',{},[(_vm.buisnessData.auth_civil_copy_path)?_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.auth_civil_copy_path",modifiers:{"auth_civil_copy_path":true}}],attrs:{"src":_vm.buisnessData.auth_civil_copy_path,"alt":"moa-image"}}):_vm._e(),_c('b-modal',{attrs:{"id":"auth_civil_copy_path","title":_vm.$t('profile.signatureAccreditation')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
                    var close = ref.close;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.$t("profile.signatureAccreditation"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" x ")])]}},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-block",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadImage(
                              _vm.buisnessData.auth_civil_copy_path,
                              (_vm.extension = _vm.buisnessData.auth_civil_copy_path
                                .split('.')
                                .pop()),
                              _vm.$t('profile.signatureAccreditation')
                            )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])]},proxy:true}],null,false,4197189486)},[[(_vm.buisnessData.auth_civil_copy_path)?_c('img',{staticClass:"img-fluid w-100",attrs:{"src":_vm.buisnessData.auth_civil_copy_path,"alt":"moa-image"}}):_vm._e()]],2)],1):(
                      _vm.auth_civil_copyType === 'document' &&
                      _vm.auth_civil_copyType !== null
                    )?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('a',{attrs:{"href":_vm.buisnessData.auth_civil_copy_path,"target":"_blank"}},[_c('canvas',{staticClass:"custom-canvas",attrs:{"id":"auth_civil_copyType-canvas"}})]),_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadItem(
                          _vm.buisnessData.auth_civil_copy_path,
                          (_vm.extension = _vm.buisnessData.auth_civil_copy_path
                            .split('.')
                            .pop()),
                          _vm.$t('profile.signatureAccreditation')
                        )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1):_vm._e()]):_vm._e()])]),_vm._l((_vm.uploadErrors.auth_civil_copy),function(error,index){return _c('div',{key:index,staticClass:"error text-start"},[_vm._v(" "+_vm._s(error)+" ")])}),(_vm.auth_civil_copyType)?_c('div',{staticClass:"edit-address",attrs:{"role":"button"},on:{"click":function($event){return _vm.editSliderAdress('CommissionerCard')}}},[_c('font-awesome-icon',{staticClass:"main-color",attrs:{"icon":"fa-solid fa-pen","size":"xl"}})],1):_vm._e()],2)]):_vm._e(),(_vm.allDocFiles.ccs !== '')?_c('div',{staticClass:"col-md-3 col-sm-12"},[_c('div',{staticClass:"form-input mb-4 new-style",class:{
              'border-main-force':
                _vm.buisnessData && _vm.buisnessData.ccs_path !== null,
            }},[_c('label',{attrs:{"for":"commissionerCard"}},[_vm._v(" "+_vm._s(_vm.$t("profile.commissionerCard"))+" "),(_vm.allDocFiles.ccs_required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"row justify-content-center align-content-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ccsType),expression:"!ccsType"}]},[_c('b-form-group',[_c('b-form-file',{attrs:{"size":"lg","id":"SignatureAccreditation","placeholder":_vm.$t('profile.filePlaceHolder'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.buissnessinfo.ccs),callback:function ($$v) {_vm.$set(_vm.buissnessinfo, "ccs", $$v)},expression:"buissnessinfo.ccs"}})],1)],1),_c('div',{},[(_vm.buisnessData)?_c('div',{staticClass:"d-flex justify-content-center align-content-center"},[(_vm.ccsType === 'image' && _vm.ccsType !== null)?_c('div',{},[(_vm.buisnessData.ccs_path)?_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.ccs_path",modifiers:{"ccs_path":true}}],attrs:{"src":_vm.buisnessData.ccs_path,"alt":"moa-image"}}):_vm._e(),_c('b-modal',{attrs:{"id":"ccs_path","title":_vm.$t('profile.commissionerCard')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
            var close = ref.close;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.$t("profile.commissionerCard"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" x ")])]}},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-block",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadImage(
                              _vm.buisnessData.ccs_path,
                              (_vm.extension = _vm.buisnessData.ccs_path
                                .split('.')
                                .pop()),
                              _vm.$t('profile.commissionerCard')
                            )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])]},proxy:true}],null,false,2835378094)},[[(_vm.buisnessData.ccs_path)?_c('img',{staticClass:"img-fluid w-100",attrs:{"src":_vm.buisnessData.ccs_path,"alt":"moa-image"}}):_vm._e()]],2)],1):(_vm.ccsType === 'document' && _vm.ccsType !== null)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('a',{attrs:{"href":_vm.buisnessData.ccs_path,"target":"_blank"}},[_c('canvas',{staticClass:"custom-canvas",attrs:{"id":"ccsType-canvas"}})]),_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadItem(
                          _vm.buisnessData.ccs_path,
                          (_vm.extension = _vm.buisnessData.ccs_path
                            .split('.')
                            .pop()),
                          _vm.$t('profile.commissionerCard')
                        )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1):_vm._e()]):_vm._e()])]),_vm._l((_vm.uploadErrors.ccs_path),function(error,index){return _c('div',{key:index,staticClass:"error text-start"},[_vm._v(" "+_vm._s(error)+" ")])}),(_vm.ccsType)?_c('div',{staticClass:"edit-address",attrs:{"role":"button"},on:{"click":function($event){return _vm.editSliderAdress('SignatureAccreditation')}}},[_c('font-awesome-icon',{staticClass:"main-color",attrs:{"icon":"fa-solid fa-pen","size":"xl"}})],1):_vm._e()],2)]):_vm._e(),(_vm.allDocFiles.rmcm !== '')?_c('div',{staticClass:"col-md-3 col-sm-12"},[_c('div',{staticClass:"form-input mb-4 new-style",class:{
              'border-main-force':
                _vm.buisnessData && _vm.buisnessData.rmcm_path !== null,
            }},[_c('label',{attrs:{"for":"certificateAdministration"}},[_vm._v(" "+_vm._s(_vm.$t("profile.certificateAdministration"))+" "),(_vm.allDocFiles.rmcm_required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"row justify-content-center align-content-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.rmcmType),expression:"!rmcmType"}]},[_c('b-form-group',[_c('b-form-file',{attrs:{"size":"lg","id":"certificateAdministration","placeholder":_vm.$t('profile.filePlaceHolder'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.buissnessinfo.rmcm),callback:function ($$v) {_vm.$set(_vm.buissnessinfo, "rmcm", $$v)},expression:"buissnessinfo.rmcm"}})],1)],1),_c('div',{},[(_vm.buisnessData)?_c('div',{staticClass:"d-flex justify-content-center align-content-center"},[(_vm.rmcmType === 'image' && _vm.rmcmType !== null)?_c('div',{},[(_vm.buisnessData.rmcm_path)?_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.rmcm_path",modifiers:{"rmcm_path":true}}],attrs:{"src":_vm.buisnessData.rmcm_path,"alt":"moa-image"}}):_vm._e(),_c('b-modal',{attrs:{"id":"rmcm_path","title":_vm.$t('profile.certificateAdministration')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
            var close = ref.close;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.$t("profile.certificateAdministration"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" x ")])]}},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-block",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadImage(
                              _vm.buisnessData.rmcm_path,
                              (_vm.extension = _vm.buisnessData.rmcm_path
                                .split('.')
                                .pop()),
                              _vm.$t('profile.certificateAdministration')
                            )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])]},proxy:true}],null,false,2644520750)},[[(_vm.buisnessData.rmcm_path)?_c('img',{staticClass:"img-fluid w-100",attrs:{"src":_vm.buisnessData.rmcm_path,"alt":"moa-image"}}):_vm._e()]],2)],1):(_vm.rmcmType === 'document' && _vm.rmcmType !== null)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('a',{attrs:{"href":_vm.buisnessData.rmcm_path,"target":"_blank"}},[_c('canvas',{staticClass:"custom-canvas",attrs:{"id":"rmcmType-canvas"}})]),_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadItem(
                          _vm.buisnessData.rmcm_path,
                          (_vm.extension = _vm.buisnessData.rmcm_path
                            .split('.')
                            .pop()),
                          _vm.$t('profile.certificateAdministration')
                        )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1):_vm._e(),(_vm.rmcmType)?_c('div',{staticClass:"edit-address",attrs:{"role":"button"},on:{"click":function($event){return _vm.editSliderAdress('certificateAdministration')}}},[_c('font-awesome-icon',{staticClass:"main-color",attrs:{"icon":"fa-solid fa-pen","size":"xl"}})],1):_vm._e()]):_vm._e()])]),_vm._l((_vm.uploadErrors.rmcm),function(error,index){return _c('div',{key:index,staticClass:"error text-start"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]):_vm._e()]),_c('b-button',{staticClass:"saveBtn btn-block py-3 border-main",attrs:{"type":"submit","disabled":_vm.btn1Disabled}},[_c('i',{staticClass:"fa fa-upload"}),_vm._v(" "+_vm._s(_vm.$t("profile.save"))+" "),(_vm.buissnessinfoUploadLoading)?_c('span',{staticClass:"loader"}):_vm._e()])],1):_vm._e(),(_vm.allDocFiles.moa !== '' || _vm.allDocFiles.sad !== '')?_c('form',{staticClass:"suppDoc mb-5",on:{"submit":function($event){$event.preventDefault();return _vm.suppDocUploadForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[(_vm.allDocFiles.moa !== '')?_c('div',{staticClass:"col-md-3 col-sm-12"},[_c('div',{staticClass:"form-input mb-4 new-style",class:{
              'border-main-force': _vm.suppData && _vm.suppData.moa_path !== null,
            }},[_c('label',{attrs:{"for":"establishmentContract"}},[_vm._v(" "+_vm._s(_vm.$t("profile.establishmentContract"))+" "),(_vm.allDocFiles.moa_required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.suppData)?_c('div',{staticClass:"row justify-content-center align-content-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.moaType),expression:"!moaType"}]},[_c('b-form-group',[_c('b-form-file',{attrs:{"size":"lg","id":"establishmentContract","placeholder":_vm.$t('profile.filePlaceHolder'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.suppDocUploadInfo.moa),callback:function ($$v) {_vm.$set(_vm.suppDocUploadInfo, "moa", $$v)},expression:"suppDocUploadInfo.moa"}})],1)],1),_c('div',{},[(_vm.buisnessData)?_c('div',{staticClass:"d-flex justify-content-center align-content-center"},[(_vm.moaType === 'image' && _vm.moaType !== null)?_c('div',{},[(_vm.suppData.moa_path)?_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.moaModal",modifiers:{"moaModal":true}}],attrs:{"src":_vm.suppData.moa_path,"alt":"moa-image"}}):_vm._e(),_c('b-modal',{attrs:{"id":"moaModal","title":_vm.$t('profile.establishmentContract')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
            var close = ref.close;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.$t("profile.establishmentContract"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" x ")])]}},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-block",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadImage(
                              _vm.suppData.moa_path,
                              (_vm.extension = _vm.suppData.moa_path.split('.').pop())
                            ),
                              _vm.$t('profile.establishmentContract')}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])]},proxy:true}],null,false,1641170062)},[[(_vm.suppData.moa_path)?_c('img',{staticClass:"img-fluid w-100",attrs:{"src":_vm.suppData.moa_path,"alt":"moa-image"}}):_vm._e()]],2)],1):(_vm.moaType === 'document' && _vm.moaType !== null)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('a',{attrs:{"href":_vm.suppData.moa_path,"target":"_blank"}},[_c('canvas',{staticClass:"custom-canvas",attrs:{"id":"moaType-canvas"}})]),_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadItem(
                          _vm.suppData.moa_path,
                          (_vm.extension = _vm.suppData.moa_path.split('.').pop()),
                          _vm.$t('profile.establishmentContract')
                        )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1):_vm._e(),(_vm.moaType)?_c('div',{staticClass:"edit-address",attrs:{"role":"button"},on:{"click":function($event){return _vm.editSliderAdress('establishmentContract')}}},[_c('font-awesome-icon',{staticClass:"main-color",attrs:{"icon":"fa-solid fa-pen","size":"xl"}})],1):_vm._e()]):_vm._e()])]):_vm._e(),_vm._l((_vm.uploadErrors.moa),function(error,index){return _c('div',{key:index,staticClass:"error text-start"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]):_vm._e(),(_vm.allDocFiles.sad !== '')?_c('div',{staticClass:"col-md-3 col-sm-12"},[_c('div',{staticClass:"form-input mb-4 new-style",class:{
              'border-main-force': _vm.suppData && _vm.suppData.sad_path !== null,
            }},[_c('label',{attrs:{"for":"IbanCertificate"}},[_vm._v(" "+_vm._s(_vm.$t("profile.letterAuthorization"))+" "),(_vm.allDocFiles.sad_required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.buisnessData)?_c('div',{staticClass:"row justify-content-center align-content-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sadType),expression:"!sadType"}]},[_c('b-form-group',[_c('b-form-file',{attrs:{"size":"lg","id":"CertificateAdministration","placeholder":_vm.$t('profile.filePlaceHolder'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.suppDocUploadInfo.sad),callback:function ($$v) {_vm.$set(_vm.suppDocUploadInfo, "sad", $$v)},expression:"suppDocUploadInfo.sad"}})],1)],1),_c('div',{},[(_vm.buisnessData)?_c('div',{staticClass:"d-flex justify-content-center align-content-center"},[(_vm.sadType === 'image')?_c('div',{},[(_vm.suppData.sad_path)?_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.sadModal",modifiers:{"sadModal":true}}],attrs:{"src":_vm.suppData.sad_path,"alt":"moa-image"}}):_vm._e(),_c('b-modal',{attrs:{"id":"sadModal","title":_vm.$t('profile.letterAuthorization')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
            var close = ref.close;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.$t("profile.letterAuthorization"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" x ")])]}},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-block",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadImage(
                              _vm.downloadItem.sad_path,
                              (_vm.extension = _vm.downloadItem.sad_path
                                .split('.')
                                .pop()),
                              _vm.$t('profile.letterAuthorization')
                            )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])]},proxy:true}],null,false,2151189710)},[[(_vm.suppData.sad_path)?_c('img',{staticClass:"img-fluid w-100",attrs:{"src":_vm.suppData.sad_path,"alt":"moa-image"}}):_vm._e()]],2)],1):(_vm.sadType === 'document' && _vm.sadType !== null)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('a',{attrs:{"href":_vm.suppData.sad_path,"target":"_blank"}},[_c('canvas',{staticClass:"custom-canvas",attrs:{"id":"sadType-canvas"}})]),_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadItem(
                          _vm.suppData.sad_path,
                          (_vm.extension = _vm.suppData.sad_path.split('.').pop()),
                          _vm.$t('profile.letterAuthorization')
                        )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1):_vm._e(),(_vm.sadType)?_c('div',{staticClass:"edit-address",attrs:{"role":"button"},on:{"click":function($event){return _vm.editSliderAdress('CertificateAdministration')}}},[_c('font-awesome-icon',{staticClass:"main-color",attrs:{"icon":"fa-solid fa-pen","size":"xl"}})],1):_vm._e()]):_vm._e()])]):_vm._e(),_vm._l((_vm.uploadErrors.sad),function(error,index){return _c('div',{key:index,staticClass:"error text-start"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]):_vm._e()]),_c('b-button',{staticClass:"saveBtn btn-block py-3 border-main",attrs:{"type":"submit","disabled":_vm.btn2Disabled}},[_c('i',{staticClass:"fa fa-upload"}),_vm._v(" "+_vm._s(_vm.$t("profile.save"))+" "),(_vm.suppDataLoading)?_c('span',{staticClass:"loader"}):_vm._e()])],1):_vm._e(),(_vm.allDocFiles.iban_number_certificate !== '')?_c('form',{staticClass:"suppDoc",on:{"submit":function($event){$event.preventDefault();return _vm.ibanUpload.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-12"},[_c('div',{staticClass:"form-input mb-4 new-style",class:{
              'border-main-force':
                _vm.ibanData && _vm.ibanData.iban_number_certificate_path !== null,
            }},[_c('label',{attrs:{"for":"LetterAuthorization"}},[_vm._v(" "+_vm._s(_vm.$t("profile.ibanCertificate"))+" "),(_vm.allDocFiles.iban_number_certificate_required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.suppData)?_c('div',{staticClass:"row justify-content-center align-content-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ibanType),expression:"!ibanType"}]},[_c('b-form-group',[_c('b-form-file',{attrs:{"size":"lg","id":"LetterAuthorization","placeholder":_vm.$t('profile.filePlaceHolder'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.bankIban.iban),callback:function ($$v) {_vm.$set(_vm.bankIban, "iban", $$v)},expression:"bankIban.iban"}})],1),_vm._l((_vm.uploadErrors.bank_iban),function(error,index){return _c('div',{key:index,staticClass:"error text-start"},[_vm._v(" "+_vm._s(error)+" ")])})],2),_c('div',{},[(_vm.buisnessData)?_c('div',{staticClass:"d-flex justify-content-center align-content-center"},[(_vm.ibanType === 'image' && _vm.ibanType !== null)?_c('div',{},[(_vm.ibanData.iban_number_certificate_path)?_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.ibanModal",modifiers:{"ibanModal":true}}],attrs:{"src":_vm.ibanData.iban_number_certificate_path,"alt":"moa-image"}}):_vm._e(),_c('b-modal',{attrs:{"id":"ibanModal","title":_vm.$t('profile.ibanCertificate')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
            var close = ref.close;
return [_c('h5',[_vm._v(" "+_vm._s(_vm.$t("profile.ibanCertificate"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" x ")])]}},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-block",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadImage(
                              _vm.ibanData.iban_number_certificate_path,
                              (_vm.extension =
                                _vm.ibanData.iban_number_certificate_path
                                  .split('.')
                                  .pop()),
                              _vm.$t('profile.ibanCertificate')
                            )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])]},proxy:true}],null,false,2804054372)},[[(_vm.ibanData.iban_number_certificate_path)?_c('img',{staticClass:"img-fluid w-100",attrs:{"src":_vm.ibanData.iban_number_certificate_path,"alt":"moa-image"}}):_vm._e()]],2)],1):(_vm.ibanType !== 'image' && _vm.ibanType !== null)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('a',{attrs:{"href":_vm.ibanData.iban_number_certificate_path,"target":"_blank"}},[_c('canvas',{staticClass:"custom-canvas",attrs:{"id":"ibanType-canvas"}})]),_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.downloadItem(
                          _vm.ibanData.iban_number_certificate_path,
                          (_vm.extension = _vm.ibanData.iban_number_certificate_path
                            .split('.')
                            .pop()),
                          _vm.$t('profile.ibanCertificate')
                        )}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "+_vm._s(_vm.$t("profile.download"))+" ")])],1):_vm._e(),(_vm.ibanType)?_c('div',{staticClass:"edit-address",attrs:{"role":"button"},on:{"click":function($event){return _vm.editSliderAdress('LetterAuthorization')}}},[_c('font-awesome-icon',{staticClass:"main-color",attrs:{"icon":"fa-solid fa-pen","size":"xl"}})],1):_vm._e()]):_vm._e()])]):_vm._e(),_vm._l((_vm.uploadErrors.iban_code),function(error,index){return _c('div',{key:index,staticClass:"error text-start"},[_vm._v(" "+_vm._s(error)+" ")])}),(_vm.unOuthMesssage)?_c('div',{staticClass:"error text-start"},[_vm._v(" "+_vm._s(_vm.unOuthMesssage)+" ")]):_vm._e()],2)])]),_c('b-button',{staticClass:"saveBtn btn-block py-3 border-main",attrs:{"type":"submit","disabled":_vm.btn3Disabled}},[_c('i',{staticClass:"fa fa-upload"}),_vm._v(" "+_vm._s(_vm.$t("profile.save"))+" "),(_vm.ibanUploadLoading)?_c('span',{staticClass:"loader"}):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"mt-5"},[_c('dynamicComponent')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }