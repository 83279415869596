var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'dynamic-form-holder': _vm.cartPage }},[_c('div',{staticClass:"newForm-holder"},_vm._l((_vm.dynamicInputs),function(input,index){return _c('div',{key:index,staticClass:"newForm"},[_c('b-form-group',[(input.dynamic_input.type == 'file')?_c('div',[_c('div',{staticClass:"row"},[_c('div',{class:{
                'col-md-6 col-sm-12': input.dynamic_input.values,
                'col-12': !input.dynamic_input.values,
              }},[(
                  _vm.form[input.dynamic_input.uuid] &&
                  _vm.filePreviews[input.dynamic_input.uuid]
                )?_c('div',[(_vm.isImage(_vm.filePreviews[input.dynamic_input.uuid]))?_c('div',{staticClass:"d-flex flex-column"},[(input.dynamic_input.title_en == 'ISO Certificate')?_c('div',{staticClass:"iso"},[_c('img',{staticClass:"img-preview",attrs:{"src":require("@/assets/images/certificates/iso.jpg")}})]):(
                      input.dynamic_input.title_en == 'CE Certificate'
                    )?_c('div',{staticClass:"iso"},[_c('img',{staticClass:"img-preview",attrs:{"src":require("@/assets/images/certificates/ce.png")}})]):(
                      input.dynamic_input.title_en == 'FDA Certificate'
                    )?_c('div',{staticClass:"iso"},[_c('img',{staticClass:"img-preview",attrs:{"src":require("@/assets/images/certificates/fda.png")}})]):_vm._e()]):_c('div')]):_vm._e()])])]):_vm._e()])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }