<template>
  <div class="new-home-slider slider-wrapper mb-5">
    <!-- data comes from backend  -->
    <VueSlickCarousel v-bind="settings" v-if="modalData && modalData.length">
      <div
        v-for="(item, index) in modalData"
        :key="index"
        class="slider-holder"
      >
        <div class="" v-if="item.image_path">
          <b-img :src="item.image_path" class="slider-image" alt="slider image">
          </b-img>
        </div>
        <div class="absolute-data">
          <div v-if="item.model_type === 'product'">
            <router-link
              :to="{ path: '/details', query: { id: item.model_id } }"
              class="bg-main text-white"
            >
              <b>{{ $t("cart.shopNow") }}</b>
            </router-link>
          </div>
          <div v-if="item.model_type === 'category'">
            <router-link
              :to="`/categories/${item.model_id}`"
              class="bg-main text-white"
            >
              <b>{{ $t("cart.shopNow") }}</b>
            </router-link>
          </div>
          <div v-if="item.model_type === 'supplier'">
            <router-link
              :to="`/suppliers/${item.model_id}`"
              class="bg-main text-white"
            >
              <b>{{ $t("cart.shopNow") }}</b>
            </router-link>
          </div>
          <!-- register links  -->
          <div v-if="item.model_type === 'b2b_register'">
            <router-link to="/b2b-register" class="bg-main text-white">
              <b>{{ $t("cart.shopNow") }}</b>
            </router-link>
          </div>
          <div v-if="item.model_type === 'b2c_register'">
            <router-link to="/user-register" class="bg-main text-white">
              <b>{{ $t("cart.shopNow") }}</b>
            </router-link>
          </div>
          <div v-if="item.model_type === 'supplier_register'">
            <a
              :href="`${supplierDomain}`"
              target="_blank"
              class="bg-main text-white"
              >{{ $t("home.suppliers") }}</a
            >
          </div>
          <div v-if="item.model_type === 'basket_promotion'">
            <router-link
              :to="{ path: '/basket_promotion', query: { id: item.model_id } }"
              class="bg-main text-white"
            >
              <b>{{ $t("cart.shopNow") }}</b>
            </router-link>
          </div>
          <div v-if="item.model_type === 'buy_get_promotion'">
            <router-link
              :to="{
                path: '/details',
                query: {
                  type: `${$t('profile.buy')} 
             ${item.model.buy_x} 
             ${$t('profile.get')} ${item.model.get_y}`,
                },
              }"
              class="bg-main text-white"
            >
              <b>{{ $t("cart.shopNow") }}</b>
            </router-link>
          </div>
          <div v-if="item.model_type === 'gift_promotion'">
            <router-link
              :to="{ path: '/details', query: { id: item.model_id } }"
              class="bg-main text-white"
            >
              <b>{{ $t("cart.shopNow") }}</b>
            </router-link>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import suppliers from "@/services/suppliers";
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        adaptiveHeight:true
      },
      images: [
        "https://images.unsplash.com/photo-1590595978583-3967cf17d2ea?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
        "https://images.unsplash.com/photo-1588807308097-fb6e5047df8c?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
        "https://images.unsplash.com/photo-1589808710416-24cf7ac026f2?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
        "https://images.unsplash.com/photo-1588796388882-a4d533c47e5e?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
      ],
      modalData: null,
      modalDataLength: 0,
    };
  },
  methods: {
    getNewCoverPromotion() {
      let type = "b2b";
      if (
        (this.buyerUserData && this.buyerUserData.type === "buyer") ||
        (this.buyerUserData && this.buyerUserData.type === "supplier")
      ) {
        type = "b2b";
      } else {
        type = "b2c";
      }
      let payload = {
        type: type,
      };
      suppliers.getNewCoverPromotion(payload).then((res) => {
        this.modalData = res.data.items;
        this.modalDataLength = res.data.items.length;
      });
    },
  },
  mounted() {
    this.getNewCoverPromotion();
  },
};
</script>

<style lang="scss" scoped>
.slider-holder {
  min-height: 450px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  @media(max-width:767px){
    min-height: fit-content !important;
  }
  .absolute-data {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-10%, -50%);
    color: $main-color;
    a {
      padding: 10px 40px;
    }
    @media (max-width: 767px) {
      top: 80%;
      left: 50%;
      transform: translate(-50%, -80%);
    }
  }

  img {
    // height: 450px;
    width: 100%;
    object-fit: contain;
    @media (max-width: 767px) {
      height: auto;
      max-width: 100%;
      max-height: 75vh;
    }
  }
}
</style>
