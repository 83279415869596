<template>
  <div class="return-replace">
    <NewReturnReplace />
  </div>
</template>

<script>
import NewReturnReplace from '@/components/pages/returns/NewReturnReplace.vue';
export default {
  components:{
    NewReturnReplace
  },
  metaInfo: {
    title: "Return Replace page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
}
</script>

<style>

</style>