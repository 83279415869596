var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.supplier && _vm.supplierMSite)?_c('div',{staticClass:"article"},[(_vm.supplierMSite.short_description_en && _vm.$i18n.locale == 'en')?_c('h2',{staticClass:"my-5",domProps:{"innerHTML":_vm._s(_vm.supplierMSite.short_description_en)}}):_vm._e(),(_vm.supplierMSite.short_description_ar && _vm.$i18n.locale == 'ar')?_c('p',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(_vm.supplierMSite.short_description_ar)}}):_vm._e(),_c('div',{staticClass:"tabs-holder"},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{staticClass:"mx-0",attrs:{"title":_vm.$t('supplier.return_policy'),"active":""}},[(
            _vm.supplierMSite.return_policy_ar || _vm.supplierMSite.return_policy_en
          )?_c('div',{staticClass:"retunPolicy"},[(_vm.supplierMSite.return_policy_en && _vm.$i18n.locale == 'en')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.supplierMSite.return_policy_en)}}):_vm._e(),(_vm.supplierMSite.return_policy_ar && _vm.$i18n.locale == 'ar')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.supplierMSite.return_policy_ar)}}):_vm._e()]):_vm._e()]),_c('b-tab',{attrs:{"title":_vm.$t('supplier.shipping_and_payment_terms')}},[(
            _vm.supplierMSite.shipping_and_payment_terms_ar ||
            _vm.supplierMSite.shipping_and_payment_terms_en
          )?_c('div',{staticClass:"shipping_and_payment_terms"},[(
              _vm.supplierMSite.shipping_and_payment_terms_en &&
              _vm.$i18n.locale == 'en'
            )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.supplierMSite.shipping_and_payment_terms_en)}}):_vm._e(),(
              _vm.supplierMSite.shipping_and_payment_terms_ar &&
              _vm.$i18n.locale == 'ar'
            )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.supplierMSite.shipping_and_payment_terms_ar)}}):_vm._e()]):_vm._e()])],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }