<template>
  <div class="return-replace">
    <NewReturnRefund />
  </div>
</template>

<script>
import NewReturnRefund from '@/components/pages/returns/NewReturnRefund.vue';
export default {
  components:{
    NewReturnRefund
  },
  metaInfo: {
    title: "Return Refund page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
}
</script>

<style>

</style>