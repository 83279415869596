var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('ProgressSlider')],1),_c('div',{staticClass:"order profile"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"lg":"3","md":"5"}},[(_vm.userInfo.item.type === 'b2c')?_c('div',[_c('div',{staticClass:"profile-menu"},[_c('h2',[_vm._v(_vm._s(_vm.$t("profile.myProfile")))]),_c('h5',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.buyerUserData.first_name)+" "+_vm._s(_vm.buyerUserData.last_name)+" ")]),(!_vm.B2CbuyerLinks)?_c('ul',_vm._l((_vm.B2CbuyerLinks),function(link,index){return _c('li',{key:index,class:{
                    'd-none':
                      link.name === 'Subscribe to the newsletter' &&
                      _vm.buyerUserData.register_mailing_list,
                  }},[_c('router-link',{class:{
                      'router-link-exact-active':
                        link.name == 'Standing Order' ||
                        link.name == 'قائمة الانتظار',
                    },attrs:{"to":link.to}},[_c('font-awesome-icon',{attrs:{"icon":("fa-solid fa-" + (link.iconName))}}),_c('span',[_vm._v(_vm._s(link.name))])],1)],1)}),0):_c('ul',_vm._l((_vm.B2CsocialLinks),function(link,index){return _c('li',{key:index,class:{
                    'd-none':
                      link.name === 'Subscribe to the newsletter' &&
                      _vm.buyerUserData.register_mailing_list,
                  }},[_c('router-link',{class:{
                      'router-link-exact-active':
                        link.name == 'Standing Order' ||
                        link.name == 'قائمة الانتظار',
                    },attrs:{"to":link.to}},[_c('font-awesome-icon',{attrs:{"icon":("fa-solid fa-" + (link.iconName))}}),_c('span',[_vm._v(_vm._s(link.name))])],1)],1)}),0)])]):_c('div',[_c('div',{staticClass:"profile-menu"},[_c('h5',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.buyerUserData.company_name)+" ")]),(!_vm.socialLogin)?_c('ul',_vm._l((_vm.B2BbuyerLinks),function(link,index){return _c('li',{key:index,class:{
                    'd-none':
                      link.name === 'Subscribe to the newsletter' &&
                      _vm.buyerUserData.register_mailing_list,
                    'router-link-exact-active': link.name == 'Standing Order',
                  }},[_c('router-link',{class:{
                      'router-link-exact-active':
                        link.name == 'Standing Order' ||
                        link.name == 'قائمة الانتظار',
                    },attrs:{"to":link.to}},[_c('font-awesome-icon',{attrs:{"icon":("fa-solid fa-" + (link.iconName))}}),_c('span',[_vm._v(_vm._s(link.name))])],1)],1)}),0):_c('ul',_vm._l((_vm.SocialLinks),function(link,index){return _c('li',{key:index,class:{
                    'd-none':
                      link.name === 'Subscribe to the newsletter' &&
                      _vm.buyerUserData.register_mailing_list,
                  }},[_c('router-link',{class:{
                      'router-link-exact-active':
                        link.name == 'Standing Order' ||
                        link.name == 'قائمة الانتظار',
                    },attrs:{"to":link.to}},[_c('font-awesome-icon',{attrs:{"icon":("fa-solid fa-" + (link.iconName))}}),_c('span',[_vm._v(_vm._s(link.name))])],1)],1)}),0)])])]),_c('b-col',{attrs:{"lg":"9","md":"7"}},[_c('StandingOrder',{attrs:{"orders":_vm.orders,"ordersLength":_vm.ordersLength},on:{"removeItem":_vm.removeStandingOrder}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }