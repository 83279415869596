<template>
  <!-- checkout details page  -->
  <div>
    <div class="wrapper">
      <div class="container">
        <div class="text-center my-5 py-5">
          <h1>{{ $t("payment.orderPlaced") }}</h1>
          <!-- company companyIban  -->
          <h5 v-html="companyIban.description" v-if="companyIban"></h5>
        </div>
        <!-- if payment_type == cach  -->
        <div class="data-holder p-5" v-if="this.payment_type === 'cach'">
          <ul class="list-data">
            <li v-if="orderDate">
              {{ $t("payment.orderDate") }} :
              <span class="bold-result">{{ orderDate | formatDate }}</span>
            </li>
            <li v-if="currency_based_total_price">
              {{ $t("payment.total") }} :
              <span class="totalbuy"
                >{{ currency_based_total_price | fixedCurrency }} {{ currency }}</span
              >
            </li>
            <li v-if="payment">
              {{ $t("payment.paymentMethod") }} :
              <span class="bold-result">{{ payment }}</span>
            </li>
          </ul>

          <h4 class="my-5">
            {{ $t("payment.checkCachResult") }}
          </h4>
        </div>
        <!-- if payment_type == bank  -->
        <div class="row" v-if="this.payment_type === 'bank'">
          <div class="col-md-6 col-sm-12 my-3">
            <div class="data-holder p-5">
              <ul class="list-data">
                <li v-if="orderDate">
                  {{ $t("payment.orderDate") }} :
                  <span class="bold-result">{{ orderDate | formatDate }}</span>
                </li>
                <li v-if="currency_based_total_price">
                  {{ $t("payment.total") }} :
                  <span class="totalbuy"
                    >{{ currency_based_total_price | fixedCurrency }} {{ currency }}</span
                  >
                </li>
                <li v-if="payment">
                  {{ $t("payment.paymentMethod") }} :
                  <span class="bold-result">{{ payment }}</span>
                </li>
              </ul>
            </div>
            <div
              class="bankInfo"
              v-if="bankInfo"
              v-html="bankInfo.description"
            ></div>
          </div>
          <div class="col-md-6 col-sm-12">
            <form class="bankData mb-5" @submit.prevent="checkoutbankUpload">
              <div class="form-input mb-4">
                <label for="bankImage">
                  {{ $t("payment.uploadImage") }}
                  <span class="text-danger">*</span>
                </label>
                <b-form-group>
                  <b-form-file
                    size="lg"
                    id="bankImage"
                    @change="uploadImage"
                    :placeholder="$t('profile.filePlaceHolder')"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>
                <div
                  class="error text-start"
                  v-for="(error, index) in uploadErrors.file"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>

              <b-form-textarea
                id="textarea-rows"
                :placeholder="$t('singleProduct.reviewInput')"
                rows="8"
                @input="check"
                v-model="bankData.comment"
                :maxlength="limit"
              ></b-form-textarea>
              <p :class="{ 'text-danger': remaining == 0 }">
                {{ instruction }}
              </p>
              <div class="my-3">
                <b-button
                  type="submit"
                  variant="outline-danger"
                  class="saveBtn btn-block py-3"
                  :disabled="btn1Disabled"
                >
                  <i class="fa fa-upload"></i> {{ $t("payment.uploadImage") }}
                  <span class="loader" v-if="loading"></span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
        <!-- if payment_type == wallet  -->
        <div v-if="this.payment_type === 'wallet'">
          <div class="">
            <div class="data-holder p-5">
              <ul class="list-data">
                <li v-if="orderDate">
                  {{ $t("payment.orderDate") }} :
                  <span class="bold-result">{{ orderDate | formatDate }}</span>
                </li>
                <li v-if="currency_based_total_price">
                  {{ $t("payment.total") }} :
                  <span class="totalbuy"
                    >{{ currency_based_total_price | fixedCurrency }} {{ currency }}</span
                  >
                </li>
                <li v-if="payment">
                  {{ $t("payment.paymentMethod") }} :
                  <span class="bold-result">{{ payment }}</span>
                </li>
              </ul>
            </div>
            <h4 class="my-5">
              {{ $t("payment.checkCachResult") }}
            </h4>
          </div>
        </div>
        <!-- if payment_type == wallet_visa  -->
        <div v-if="this.payment_type === 'wallet_visa'">
          <div class="">
            <div class="data-holder p-5">
              <ul class="list-data">
                <li v-if="orderDate">
                  {{ $t("payment.orderDate") }} :
                  <span class="bold-result">{{ orderDate | formatDate }}</span>
                </li>
                <li v-if="wallet_paied">
                  {{ $t("profile.walletPayment") }} :
                  <span class="bold-result"
                    >{{ wallet_paied | fixedCurrency }} {{ currency }}</span
                  >
                </li>
                <li v-if="visa_paied">
                  {{ $t("profile.visaPayment") }} :
                  <span class="bold-result"
                    >{{ visa_paied | fixedCurrency }} {{ currency }}</span
                  >
                </li>

                <li v-if="currency_based_total_price">
                  {{ $t("payment.total") }} :
                  <span class="totalbuy"
                    >{{ currency_based_total_price | fixedCurrency }} {{ currency }}</span
                  >
                </li>
                <li v-if="payment">
                  {{ $t("payment.paymentMethod") }} :
                  <span class="bold-result">{{ payment }}</span>
                </li>
              </ul>
            </div>
            <h4 class="my-5">
              {{ $t("payment.checkCachResult") }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//checkout details page
import suppliers from "@/services/suppliers";
import profile from "@/services/profile";
export default {
  metaInfo: {
    title: "checkout details page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  data() {
    return {
      order_serial: this.$route.query.order_serial,
      orderDate: this.$route.query.date,
      total_price: parseFloat(this.$route.query.currency_based_total_price),
      payment_type: this.$route.query.payment_type,
      payment: this.$route.query.payment,
      orderId: this.$route.query.orderId,
      wallet_paied: this.$route.query.wallet_paied,
      visa_paied: this.$route.query.visa_paied,

      bankData: {
        image: null,
        comment: "",
        uuid: this.$route.query.uuid,
      },
      uploadErrors: [],
      btn1Disabled: false,
      loading: false,
      id: this.$route.query.id,
      bankInfo: null,
      limit: 300,
      companyIban: null,
    };
  },
  mounted() {
    if (
      this.payment_type === "cach" ||
      this.payment_type === "wallet" ||
      this.payment_type === "wallet_visa"
    ) {
      setTimeout(() => {
        this.$router.push(`/viewOrderDetails?id=${this.orderId}`);
      }, 8000);
    }
    this.getBankComment();
    this.getCompanyIban();
  },
  methods: {
    /**
     * @vuese
     * checkout bank Upload function
     */
    async checkoutbankUpload() {
      this.loading = true;
      this.btn1Disabled = true;
      let formData = new FormData();

      if (this.bankData.image !== null) {
        formData.append("file", this.bankData.image);
      }
      formData.append("comment", this.bankData.comment);
      formData.append("order_uuid", this.bankData.uuid);

      await suppliers
        .bankCheckout(formData)
        .then((res) => {
          if (res.status == 200) {
            this.sucessMsg(res.data.message);
            this.$router.push({
              path: "/viewOrderDetails",
              query: {
                id: res.data.items.order_id,
              },
            });
          }
          
          this.$store.dispatch("cart/getCartProducts");
        })
        .catch((error) => {
          const err = Object.values(error)[2].data;
          this.uploadErrors = err.items;
          this.errMsg(err.message);
        })
        .finally(() => {
          this.loading = false;
          this.btn1Disabled = false;
        });
    },
    /**
     * @vuese
     * upload Image function
     */
    uploadImage(event) {
      this.bankData.image = event.target.files[0];

    },
    /**
     * @vuese
     * get Bank Comment function
     */
    getBankComment() {
      profile
        .bankComment()
        .then((res) => {
          this.bankInfo = res.data.items;
        })
    },
    /**
     * @vuese
     * checkt function to limit the input characters number
     */
    check: function () {
      this.bankData.comment = this.bankData.comment.substr(0, this.limit);
    },
    /**
     * @vuese
     * get Company Iban  function
     */
    getCompanyIban() {
      profile
        .companyIban()
        .then((res) => {
          
          this.companyIban = res.data.items;
        })
    },
  },
  computed: {
    instruction: function () {
      return this.bankData.comment == ""
        ? ` ${this.$t("profile.limit")}: ` +
            this.limit +
            ` ${this.$t("profile.char")}  `
        : ` ${this.$t("profile.remain")}: ` +
            this.remaining +
            ` ${this.$t("profile.char")} `;
    },

    remaining: function () {
      return this.limit - this.bankData.comment.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-holder {
  .list-data {
    list-style-type: disc;
    font-size: 20px;
    color: #8f8f8f;
    line-height: 2.4;
  }

  .bold-result {
    color: #646464;
    font-weight: bold;
    font-size: 25px !important;
  }

  .totalbuy {
    color: #000;
    font-weight: bold;
    font-size: 25px;
  }
}

.bankInfo {
  color: #8f8f8f;
  font-size: 18px;
  line-height: 1.8;
  text-transform: capitalize;
  padding: 0 20px;
  margin: 2% 0;
}
</style>
