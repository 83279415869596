var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-layout b2bLayout",class:_vm.$i18n.locale},[_c('div',{attrs:{"id":"allTheNav"}},[_c('div',{staticClass:"side-nav",class:{ sidenavopen: _vm.opened },attrs:{"id":"side-menu"},on:{"mouseenter":function($event){_vm.opened = true},"mouseleave":function($event){_vm.opened = false}}},[_c('div',{staticClass:"d-flex align-items-center toggle-menu",on:{"click":function($event){$event.preventDefault();_vm.opened = !_vm.opened}}},[(_vm.opened)?_c('span',[_c('span',[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-bars-staggered","size":"3x"}})],1),_c('span',{staticClass:"mx-2 h4"},[_vm._v(_vm._s(_vm.$t("profile.hideMenu")))])]):_c('span',[_c('span',[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-bars-staggered","size":"2x"}})],1)])]),(_vm.userInfo.item.type === 'b2c')?_c('SideMenu',{attrs:{"userBades":_vm.userBades,"opened":_vm.opened},on:{"closeSideMenu":function($event){_vm.opened = false}}}):_c('SideMenuB2b',{attrs:{"userBades":_vm.userBades,"opened":_vm.opened},on:{"closeSideMenu":function($event){_vm.opened = false}}})],1),_c('div',{class:{
        mainopen: _vm.ProfileLayout && _vm.opened == true,
        mainClose: _vm.ProfileLayout && _vm.opened == false,
        fullWidth: _vm.complteProfilePage,
      },attrs:{"id":"main"}},[_c('Nav',{attrs:{"siteLogo":_vm.siteLogo}}),(_vm.massgeOfVerify)?_c('b-alert',{staticClass:"activeAccountMessage",attrs:{"variant":"danger","show":""}},[_c('b-container',[_c('router-link',{staticClass:"otp-link text-danger",attrs:{"to":"/otp-verification"}},[_vm._v(" "+_vm._s(_vm.massgeOfVerify)+" "),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-right-long"}})],1)],1)],1):_vm._e(),(
        (_vm.userInfo && !_vm.userInfo.item.is_verified && !_vm.buyerUserData) ||
        (_vm.userInfo && !_vm.buyerUserData)
        )?_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('b-container',[_vm._v(" "+_vm._s(_vm.$t("home.verifyEmailMessage"))+" ")])],1):_vm._e(),(
          _vm.buyerUserData &&
          _vm.buyerUserData.client_subscription &&
          _vm.buyerUserData.client_subscription.payment_method == 'bank' &&
          _vm.buyerUserData.client_subscription.status == 'pending'
        )?_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('b-container',[_vm._v(" "+_vm._s(_vm.$t("home.waitUntilApprove"))+" ")])],1):_vm._e(),(
            _vm.buyerUserData &&
            _vm.buyerUserData.trial_end_date
          )?_c('div',{staticClass:"cursor-pointer bg-warning-dialog",attrs:{"variant":"warning","show":""},on:{"click":_vm.goProfilePage}},[_c('div',{staticClass:"container-fluid",on:{"click":_vm.goProfilePage}},[_vm._v(" "+_vm._s(_vm.$t("home.freeTryEndDate"))+" "),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.buyerUserData.trial_end_date))])])]):_vm._e(),_c('div',{staticClass:"router-holder"},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('router-view',{staticClass:"view"})],1)],1),(_vm.visible)?_c('div',{staticClass:"top-btn",on:{"click":_vm.goTop}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"48","height":"48","viewBox":"0 0 24 24","fill":"none","stroke":"#4a5568","stroke-width":"1","stroke-linecap":"square","stroke-linejoin":"arcs"}},[_c('path',{attrs:{"d":"M18 15l-6-6-6 6"}})])]):_vm._e(),_c('cookizComponent'),_c('NewFooter')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }