<template>
  <!-- wishlist page (favorite ) -->
  <div class="cart-page">
    <div class="container">
      <!-- import wishlist (favorite ) component  -->
      <Wishlist></Wishlist>
    </div>
  </div>
</template>
<script>
import Wishlist from "@/components/pages/wishlist/Wishlist.vue";
export default {
  metaInfo: {
    title: "wishlist page (favorite)",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  components: {
    Wishlist,
  },
};
</script>
<style lang="scss" scoped>
.cart-page {
  padding: 2rem;
}
</style>
