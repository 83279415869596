<template>
  <!-- articel component  -->
  <div class="article" v-if="supplier && supplierMSite">
    <h2
      v-html="supplierMSite.short_description_en"
      v-if="supplierMSite.short_description_en && $i18n.locale == 'en'"
      class="my-5"
    ></h2>
    <p
      v-html="supplierMSite.short_description_ar"
      v-if="supplierMSite.short_description_ar && $i18n.locale == 'ar'"
      class="mt-2"
    ></p>

    <div class="tabs-holder">
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('supplier.return_policy')" class="mx-0" active>
          <div
            class="retunPolicy"
            v-if="
              supplierMSite.return_policy_ar || supplierMSite.return_policy_en
            "
          >
            <p
              v-html="supplierMSite.return_policy_en"
              v-if="supplierMSite.return_policy_en && $i18n.locale == 'en'"
            ></p>
            <p
              v-html="supplierMSite.return_policy_ar"
              v-if="supplierMSite.return_policy_ar && $i18n.locale == 'ar'"
            ></p>
          </div>
        </b-tab>
        <b-tab :title="$t('supplier.shipping_and_payment_terms')">
          <div
            class="shipping_and_payment_terms"
            v-if="
              supplierMSite.shipping_and_payment_terms_ar ||
              supplierMSite.shipping_and_payment_terms_en
            "
          >
            <p
              v-if="
                supplierMSite.shipping_and_payment_terms_en &&
                $i18n.locale == 'en'
              "
              v-html="supplierMSite.shipping_and_payment_terms_en"
            ></p>
            <p
              v-if="
                supplierMSite.shipping_and_payment_terms_ar &&
                $i18n.locale == 'ar'
              "
              v-html="supplierMSite.shipping_and_payment_terms_ar"
            ></p>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { count: 0 };
  },
  /**
   * @vuese
   *  supplier and supplierMSite as props
   */
  props: {
    supplier: {
      // supplier prop
      type: Object,
      required: false,
    },
    supplierMSite: {
      // supplier Micro Site data prop
      type: Object,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
/**
  *  component style
*/
.article {
  img {
    height: 300px;
    width: 100%;
    object-fit: contain;
  }
}
.nav-link{
  margin: 0 !important;
}
</style>
