<template>
  <!-- suppliers page  -->
  <div class="suppliers-body">
    <div
      class="navigation d-none d-lg-flex justify-content-center align-items-center w-75 mx-auto my-4"
    >
      <b-breadcrumb :items="items"></b-breadcrumb>
    </div>

    <div class="suppliers py-4">
      <div class="container">
        <b-row v-if="loading">
          <b-col class="mb-2" lg="2" sm="6" v-for="x in 10" :key="x">
            <b-skeleton-img></b-skeleton-img>
            <b-card>
              <b-skeleton
                animation="fade"
                width="60%"
                class="border-none"
              ></b-skeleton>
              <b-skeleton
                animation="fade"
                width="85%"
                class="border-none"
              ></b-skeleton>
            </b-card>
          </b-col>
        </b-row>
        <div class="dropdown d-flex justify-content-end" v-if="!loading && buyerUserData">
          <button
            class="btn dropdown-toggle border-main"
            @click="toggleDropdown"
            v-if="suppliers && suppliers.length"
          >
            <font-awesome-icon icon="fa-solid fa-filter" />
          </button>
          <button v-if="selectedOption"
            class="btn mx-2 border-main"
            @click="selectedOption = null ; getSuppliersByTypes()"
          >
          <font-awesome-icon icon="fa-solid fa-x" />
          </button>
          <div
            class="dropdown-menu"
            v-if="isDropdownOpen"
            style="min-width: 200px"
            :class="{ 'd-block': isDropdownOpen }"
          >
            <div
              class="dropdown-item"
              v-for="option in suppliersTypes"
              :key="option.id"
              @click="selectOption(option.title_en)"
            >
              {{ $i18n.locale == "en" ? option.title_en : option.title_ar }}
              <i class="fa fa-check" v-if="selectedOption == option.title_en"></i>
            </div>
          </div>
        </div>
        <div
          class="row suppliers-data justify-content-center mt-4"
          v-if="!loading"
        >
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-2 mx-2 supplier-content"
            v-for="supplier in suppliers"
            :key="supplier.id"
          >
            <SingleSupplier :supplier="supplier"></SingleSupplier>
          </div>
        </div>
        <div
          class="text-center d-flex justify-content-center align-items-center mt-5"
        >
          <Paginate
            v-if="suppliers"
            :total-pages="totalPages"
            :per-page="totalPages"
            :current-page="page"
            @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// suppliers page
import SingleSupplier from "@/components/pages/suppliers/SingleSupplier.vue";
import suppliers from "@/services/suppliers";
import Paginate from "@/components/global/Paginate.vue";
import moment from "moment";
export default {
  metaInfo: {
    title: "suppliers page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  components: {
    SingleSupplier,
    Paginate,
  },
  data() {
    return {
      items: [
        {
          text: this.$t("supplier.home"),
          href: "/",
        },
        {
          text: this.$t("supplier.suppliers"),
          href: "#",
          active: true,
        },
      ],
      suppliers: [],
      loading: false,
      perPage: 5,
      total: 0,
      currentPage: 1,

      page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: 10,
      enterpageno: "",
      selectedTimeFilter: "allTimes",
      payload: {
        page: this.page,
        created_at_from: null,
        created_at_to: null,
      },
      suppliersTypes: [],
      selectedTypes: [],
      selectedOption:null,
      isDropdownOpen: false,
      count:200
    };
  },
  methods: {
    /**
     * @vuese
     * this function used get Suppliers
     */
    getSuppliersByTypes() {
      this.loading = true;
      suppliers
        .getSuppliersByTypes(this.selectedOption , this.page , this.count)
        .then((resp) => {
          this.suppliers = resp.data.items.data;
          this.total = resp.data.items.total;
          this.totalPages = Math.ceil(
            resp.data.items.total / resp.data.items.per_page
          ); // Calculate total records

          this.totalRecords = resp.data.items.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSuppliersByTypesFilters() {
      this.loading = true;
      suppliers
        .getSuppliersByTypes(this.selectedOption , this.page , this.count)
        .then((resp) => {
          this.suppliers = resp.data.items.data;
          this.total = resp.data.items.total;
          this.totalPages = Math.ceil(
            resp.data.items.total / resp.data.items.per_page
          ); // Calculate total records

          this.totalRecords = resp.data.items.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * @vuese
     * this function used for pagination
     */
    onPageChange(page) {
      this.page = page;
      if (this.selectedTimeFilter === "allTimes") {
        this.getSuppliersByTypes(this.selectedOption , page , this.count)
      } else {
        this.chooseTimeFilter(this.selectedTimeFilter, page);
      }
    },

    /**
     * @vuese
     * this function used for pagination
     */
    onChangeRecordsPerPage() {
      this.getSuppliersByTypes(this.selectedOption , this.page)
    },
    chooseTimeFilter(time, page) {
      this.firstDayOfCurrentMonth = moment()
        .startOf("month")
        .format("YYYY-M-D");
      this.lastDayOfCurrentMonth = moment().endOf("month").format("YYYY-M-D");
      this.firstDayOfPreviousMonth = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-M-D");
      this.lastDayOfPreviousMonth = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-M-D");

      this.selectedTimeFilter = time;
      if (time == "currentMonth") {
        this.payload = {
          page: page,
          created_at_from: this.firstDayOfCurrentMonth,
          created_at_to: this.lastDayOfCurrentMonth,
        };
      } else if (time == "prevMonth") {
        this.payload = {
          page: page,
          created_at_from: this.firstDayOfPreviousMonth,
          created_at_to: this.lastDayOfPreviousMonth,
        };
      } else if (time == "allTimes") {
        this.payload = {
          page: page,
          created_at_from: null,
          created_at_to: null,
        };
      }
      this.getSuppliersByTypes(this.selectedOption , this.page)
    },

    /**
     * @vuese
     * this function used for pagination
     */
    gotoPage() {
      if (!isNaN(parseInt(this.enterpageno))) {
        this.page = parseInt(this.enterpageno);
        this.getSuppliersByTypes(this.selectedOption , this.page)
      }
    },
    supplierByType() {
      suppliers
        .supplierByType()
        .then((res) => {
          this.suppliersTypes = res.data.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // fetchDataBasedOnSelectedTypes() {
    //   const selectedTypeIds = this.selectedTypes.join(",");
    //   suppliers
    //     .getSuppliersByTypes(selectedTypeIds)
    //     .then((response) => {
    //       // Assuming the API returns an array of suppliers in response.data
    //       this.suppliers = response.data;
    //     })
    //     .catch((error) => {
    //       console.error(
    //         "Failed to fetch suppliers based on selected types:",
    //         error
    //       );
    //     });
    // },
    fetchDataBasedOnSelectedType() {
      this.loading = true
      if (this.selectedOption) {
        suppliers
          .getSuppliersByTypes(this.selectedOption , this.page , this.count)
          .then((resp) => {
            this.suppliers = resp.data.items.data;
            this.total = resp.data.items.total;
            this.totalPages = Math.ceil(
              resp.data.items.total / resp.data.items.per_page
            ); // Calculate total records

            this.totalRecords = resp.data.items.total;
          })
          .catch((error) => {
            console.error(
              "Failed to fetch suppliers for selected type:",
              error
            );
          }).finally(()=>{
            this.loading = false
          })
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    // selectOption(option) {
    //   const index = this.selectedOptions.findIndex((o) => o.id === option.id);
    //   if (index > -1) {
    //     this.selectedOptions.splice(index, 1);
    //   } else {
    //     this.selectedOptions.push(option);
    //   }
    //   // Update selectedTypes based on selectedOptions
    //   this.selectedTypes = this.selectedOptions.map((o) => o.id);
    // },
    selectOption(optionId) {
      this.selectedOption = optionId;
      this.isDropdownOpen = false; // Close the dropdown
      this.fetchDataBasedOnSelectedType();
    },
    isSelected(option) {
      return this.selectedOptions.some((o) => o.id === option.id);
    },
  },
  mounted() {
    this.getSuppliersByTypes(this.selectedOption , this.page)
    this.supplierByType();
  },
  computed: {
    rows() {
      return this.suppliers.length;
    },
  },
  watch: {
    selectedTimeFilter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1;
      }
    },
    // selectedOptions(newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.fetchDataBasedOnSelectedTypes();
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.suppliers {
  background-color: #f9f8f5;
  .suppliers-data {
    color: #000;
    .supplier-content {
      padding: 0.6rem;
    }
  }
}
.border-main {
  &:focus,
  &:active {
    color: $main-color !important;
  }
}
.dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
.dropdown-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
