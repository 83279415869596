export default {
  createCustomerAccount: "انشاء حساب عميل تجزئه",
  mainInformation: "المعلومات الاساسية",
  alreadyHaveAccount: "لدي حساب تسجيل الدخول",
  firstName: "الاسم الاول",
  lastName: "اسم العائلة",
  email: "البريد الاليكتروني",
  password: "كلمة المرور",
  confirmPassword: "تأكيد كلمة المرور",
  countryCode: "رمز الدولة",
  phone: "رقم الهاتف",
  chooseOneOfTheWays: "اختار احد الطرق لتفعيل حسابك",
  PleaseReview: "يرجى الاطلاع و الموافقة علي",
  termsConditions: "الشروط و الأحكام",
  toCompleteTheRegistration: "لاتمام عملية التسجيل",
  subscribeTheNewsletter: "الاشتراك فى النشرة الدورية",
  submit: "تسجيل",
  unableRegister: "في حالة تعذر التسجيل يرجي الاتصال بنا للمساعدة",
  registrationCompany: "تسجيل لحسابات المطاعم و الفنادق و الشركات شراء بالجملة",
  companyName: "اسم الشركة",
  arabicCompanyName: "اسم الشركة باللغة العربيه",
  englishCompanyName: "اسم الشركة باللغة الانجليزية",
  jobTitle: "الوظيفة",
  pleaseVerification: "يرجى التحقق من الحساب",
  codeSent: "تم إرسال رمز التحقق إلى هاتفك",
  verificationAccount: "حساب التحقق",
  verification: "تحقق",
  notArrive: "لم يصل ؟ إعادة إرسال",
  openEmail: "يرجى فتح بريدك الإلكتروني للتحقق من حسابك لتتمكن من تسجيل الدخول",
  otpVerify:' يجب عليك تفعيل رقم هاتفك عن طريق رسالة الهاتف',
  haveNotAccount: "ليس لدي حساب انشاء حساب جديد",
  resetPassword: "يرجى كتابة كلمة المرور الجديدة",
  passwordConfirmation: "إعادة كلمة المرور",
  save: "حفظ",
  department: "القسم",
  selectDept: "اختر القسم",
  selectPrefixes:'اختر المسمى',
  newPolicy:'من خلال التسجيل ، فإنك توافق على ',
  verify:'التحقق',
  wholeSale:'عملاء الجمله',
  passCheck1:'يجب أن يكون طول نص حقل على الأقل 8 حروفٍ/حرفًا.',
  passCheck2:'يجب ان يحتوى الرمز السرى على حروف كبيره و صغيره وارقام و رموز',
  searchAddress:'بحث عن  عنوان',
  search:'بحث',
  resendCode:'ارسال كود التحقق مره اخرى'
};
