export default {
  contactUs: "اتصل بنا",
  writeMessage: "اكتب رسالتك",
  formName: "الاسم بالكامل",
  formEmail: "البريد الاليكتروني",
  formPhone: "رقم الهاتف",
  formSubject: "الموضوع",
  formMessage: "رسالتك",
  formSend: "ارسال",
  phone: "الهاتف",
  address: "العنوان",
};
