var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-silder specialOffers"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"bestDeals"},[_c('span',{staticClass:"product-info"},[_c('h4',{staticClass:"top-header"},[_vm._v(_vm._s(_vm.$t("home.monthlyOffers")))])]),_c('div',{staticClass:"tabs-holder"},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":_vm.$t('profile.buyXgetYOffer'),"active":""}},[(_vm.dataLength > 0)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('router-link',{staticClass:"showAllLink",attrs:{"to":{
                path: '/new-deals',
                query: { type: ("" + (_vm.$t('profile.buyXgetYOffer'))) },
              }}},[_vm._v(" "+_vm._s(_vm.$t("home.showAll"))+" ")])],1):_vm._e(),(_vm.dataLength > 0)?_c('div',{},[(_vm.dataLength)?_c('VueSlickCarousel',_vm._b({staticClass:"my-5"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.buyAndGet),function(deal,index){return _c('div',{key:index,staticClass:"slider-holder"},[_c('ProductCard',{attrs:{"slider":deal,"sort":'buyXgetY',"dealType":((_vm.$t('profile.buy')) + " \n                  " + (deal.buy_get_promotion_running_by_type.promotion.buy_x) + " \n                  " + (_vm.$t('profile.get')) + " " + (deal.buy_get_promotion_running_by_type.promotion.get_y))}})],1)}),0):_vm._e()],1):_c('div',{},[_c('h3',[_vm._v(_vm._s(_vm.$t('cart.noDataMatch')))])])]),_c('b-tab',{attrs:{"title":_vm.$t('profile.buXGetGift')}},[(_vm.giftOffersLength > 0)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('router-link',{staticClass:"showAllLink",attrs:{"to":{
                path: '/new-deals',
                query: { type: ((_vm.$t('profile.buy')) + " " + (_vm.$t('profile.and')) + " " + (_vm.$t('profile.get')) + " " + (_vm.$t('profile.gift'))) },
              }}},[_vm._v(" "+_vm._s(_vm.$t("home.showAll"))+" ")])],1):_vm._e(),(_vm.giftOffersLength > 0)?_c('div',{},[(_vm.giftOffersLength)?_c('VueSlickCarousel',_vm._b({staticClass:"my-5"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.giftOffers),function(deal,index){return _c('div',{key:index},[_c('ProductCard',{attrs:{"slider":deal,"sort":'buXGetGift',"dealType":((_vm.$t('profile.buy')) + " \n                  " + (deal.buy_gift_promotions_running_by_type.buy_x) + " \n                  " + (_vm.$t('profile.get')) + " " + (deal.buy_gift_promotions_running_by_type.gift_product_supplier.product.title) + " " + (_vm.$t('profile.free')))}})],1)}),0):_vm._e()],1):_c('div',{},[_c('h3',[_vm._v(_vm._s(_vm.$t('cart.noDataMatch')))])])]),_c('b-tab',{attrs:{"title":_vm.$t('profile.monthlyOffers')}},[_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.dealsLength > 0)?_c('router-link',{staticClass:"showAllLink",attrs:{"to":"/monthly-offers"}},[_vm._v(" "+_vm._s(_vm.$t("home.showAll"))+" ")]):_vm._e()],1),(_vm.dealsLength > 0)?_c('div',{},[(_vm.dealsLength)?_c('VueSlickCarousel',_vm._b({staticClass:"my-5"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.deals),function(deal,index){return _c('div',{key:index},[_c('ProductCard',{attrs:{"slider":deal,"dealType":_vm.$t('profile.monthlyOffers')}})],1)}),0):_vm._e()],1):_c('div',{},[_c('h3',[_vm._v(_vm._s(_vm.$t('cart.noDataMatch')))])])]),_c('b-tab',{attrs:{"title":_vm.$t('profile.basketDeals')}},[(_vm.basketDataLength > 0)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('router-link',{staticClass:"showAllLink",attrs:{"to":{
                path: '/new-deals',
                query: { type: this.$t('profile.basketDeals') },
              }}},[_vm._v(" "+_vm._s(_vm.$t("home.showAll"))+" ")])],1):_vm._e(),(_vm.basketDataLength > 0)?_c('div',{},[(_vm.basketDataLength)?_c('VueSlickCarousel',_vm._b({staticClass:"my-5"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.basketDealData),function(deal,index){return _c('div',{key:index},[_c('BasketCard',{attrs:{"slider":deal,"dealType":_vm.$t('profile.basketDeals')}})],1)}),0):_vm._e()],1):_c('div',{},[_c('h3',[_vm._v(_vm._s(_vm.$t('cart.noDataMatch')))])])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }