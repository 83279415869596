<template>
  <div>
    <!-- rfq icon  -->
    <span class="svg-icon">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
        style="enable-background: new 0 0 500 500"
        xml:space="preserve"
      >
        <g id="_x37_H9tNz_00000108299915986863222420000008725679775806346410_">
          <g>
            <path
              d="M112.25,408.15c0-10.29,0-19.84,0-29.39c0-6.48-0.03-12.97,0.03-19.45c0.05-5.01,2.66-7.95,6.88-7.96
c4.14-0.01,6.91,3.04,6.93,8.03c0.06,14.29,0.01,28.59,0,42.88c0,1.74,0,3.47,0,5.76c10.26,0,20.07,0,29.89,0
c64.84,0,129.67,0,194.51,0.06c2.45,0,5.24,0.05,7.25,1.18c1.62,0.91,2.97,3.38,3.3,5.34c3.4,20.31,20,30.95,39.77,25.27
c14.79-4.25,22.2-14.03,22.2-29.42c0.02-116.26,0.07-232.53-0.04-348.79c-0.01-10.93,2.78-20.59,10.52-29.47
c-1.97-0.2-3.17-0.42-4.37-0.42c-89.15-0.01-178.3,0.01-267.45-0.01c-10.88,0-20.66,2.67-28.55,10.65
c-4.71,4.76-7.04,10.37-7.02,17.06c0.04,17.24,0.02,34.48-0.01,51.72c0,1.61,0.08,3.28-0.28,4.83c-0.84,3.63-3.24,5.6-7.01,5.36
c-3.72-0.24-5.93-2.37-6.43-6.09c-0.17-1.31-0.1-2.65-0.1-3.97c-0.01-16.06,0.63-32.15-0.15-48.18
c-1.22-25.09,17.85-40.66,36.97-44.2c3.6-0.67,7.31-0.97,10.97-0.97c99.76-0.05,199.52-0.05,299.28-0.03
c19.76,0,38.65,17.23,40.55,36.87c0.71,7.34-1.7,10.03-9.02,10.05c-16.06,0.05-32.12,0.24-48.18,0.07
c-4.32-0.05-5.77,1.02-5.76,5.63c0.14,112.88-0.39,225.75,0.46,338.62c0.22,29.46-23.76,46.09-45.81,46.1
c-90.33,0.06-180.66,0.09-270.99-0.03c-19.56-0.03-38.68-18.74-39.84-38.29c-0.34-5.82,2.36-8.74,8.35-8.8
C96.58,408.1,104.08,408.15,112.25,408.15z M356.86,441.41c-2.73-5.06-5.82-9.21-7.24-13.88c-1.4-4.62-3.57-5.63-8.07-5.62
c-78.67,0.14-157.34,0.1-236.01,0.1c-3.04,0-6.09,0-9.9,0c1.07,2.2,1.6,3.88,2.59,5.23c6.33,8.69,14.63,14.11,25.64,14.12
c75.87,0.07,151.74,0.04,227.61,0.05C353.19,441.41,354.9,441.41,356.86,441.41z M484.26,50.8c-1.81-9.31-14.91-18.39-22.88-18.58
c-8.3-0.2-20.21,9.93-22.39,18.58C454.03,50.8,468.97,50.8,484.26,50.8z"
            />
            <path
              d="M289.76,290.16c6.43,6.02,12.87,11.85,19.07,17.92c3.98,3.89,4.08,8.06,0.72,11.18c-3.04,2.83-7.03,2.35-10.84-1.45
c-6.24-6.23-12.42-12.53-17.74-17.9c-9.32,4.45-17.56,9.59-26.53,12.41c-18.17,5.71-33.67,0.04-46.09-14.06
c-11.2-12.72-15.33-28.02-14.9-44.6c0.63-23.81,5.29-46.79,16.18-68.16c7.73-15.16,19.36-26.14,35.83-31.56
c20.95-6.9,39.62-1.05,52.77,16.71c8.71,11.76,13.43,25.16,15.56,39.48c3.69,24.77-1.12,47.74-15.37,68.52
C296.01,282.16,293.3,285.46,289.76,290.16z M270.52,287.65c-4.8-4.32-9.39-8.23-13.72-12.41c-3.79-3.66-3.97-7.83-0.87-10.85
c3.14-3.06,7.11-2.76,10.88,0.99c4.36,4.34,8.62,8.78,13.21,13.47c1.49-1.63,2.76-2.83,3.81-4.2
c13.13-17.27,19.17-36.6,16.57-58.32c-1.67-13.94-5.62-27.1-14.42-38.38c-7.35-9.41-16.79-14.25-28.92-12.34
c-9.08,1.43-17.54,4.86-23.73,11.72c-4.87,5.39-9.62,11.38-12.55,17.93c-10.04,22.47-15.37,46-12.23,70.83
c1,7.89,4.19,15.01,9.14,21.25C230.87,304.01,251.73,304.58,270.52,287.65z"
            />
            <path
              d="M20.1,228.57c1.69,3.54,2.88,6.87,4.72,9.79c13.3,21.05,29.14,39.97,48.66,55.53c3.79,3.02,8.66,4.81,13.23,6.66
c3.86,1.56,6.16,4.23,5.57,7.82c-0.61,3.75-3.6,6.64-7.55,5.65c-5.32-1.32-10.8-3.15-15.38-6.07
c-14.18-9.05-25.23-21.58-35.95-34.32c-5.02-5.96-9.6-12.29-14.38-18.45c-0.86-1.11-1.74-2.2-2.62-3.29
c-0.45,0.07-0.89,0.14-1.34,0.21c0,5.81,0.13,11.63-0.03,17.44c-0.3,11.33-0.72,22.66-1.14,34c-0.2,5.32-2.9,8.51-7.03,8.49
C2.53,312-0.1,308.58,0,303.14c0.58-31.36,1.24-62.71,1.68-94.07c0.09-6.46-0.24-12.98-1.01-19.4
c-2.81-23.63,8.99-33.73,28.92-36.84c15.63-2.43,30.56,0.31,43.8,9.55c18.72,13.06,19.52,34.81,1.74,49.04
c-12.78,10.23-28.19,13.54-43.88,15.88C27.8,227.83,24.29,228.11,20.1,228.57z M16.12,214.88c6.91-0.83,13.63-1.28,20.2-2.49
c10.58-1.96,20.95-4.76,29.73-11.48c10.33-7.91,10.83-18.56,0.69-26.05c-12.82-9.47-27.2-11.39-42.27-6.46
c-8.07,2.64-10.48,6.39-10.19,14.92C14.64,193.44,15.44,203.54,16.12,214.88z"
            />
            <path
              d="M273.12,370.38c-36.98,0-73.97,0.01-110.95-0.01c-6.49,0-10-3.39-9.18-8.92c0.58-3.93,2.74-6.48,6.96-6.68
c2.21-0.11,4.42-0.03,6.63-0.03c71.61,0,143.22,0,214.83,0c1.77,0,3.54-0.05,5.3,0.1c3.95,0.35,6.53,3.32,6.61,7.45
c0.09,4.17-2.31,7.37-6.16,7.91c-1.88,0.26-3.82,0.18-5.74,0.18C345.31,370.39,309.21,370.38,273.12,370.38z"
            />
            <path
              d="M128.99,225.13c8.79,0,17.59-0.21,26.37,0.08c5.57,0.18,11.15,0.93,16.68,1.75c4.9,0.73,7.44,4.33,6.62,8.73
c-0.7,3.78-4.14,5.99-8.84,5.19c-11.82-2.01-23.66-2.45-35.59-1.19c-6.2,0.65-6.31,0.49-6.34,6.71
c-0.09,19.59-0.15,39.18-0.11,58.78c0.01,3.82-0.61,7.17-4.55,8.68c-4.66,1.79-9.17-1.48-9.22-6.9
c-0.15-17.53-0.36-35.06-0.05-52.59c0.39-22.53,0.99-45.07,2.06-67.57c0.37-7.87,2.11-15.73,3.84-23.45
c0.43-1.94,3-4.22,5.04-4.84c17.91-5.38,36.22-6.04,54.67-3.56c4.42,0.59,6.8,4.07,6.14,8.16c-0.65,4.03-3.85,6.31-8.52,5.67
c-13.26-1.8-26.41-1.21-39.6,0.87c-4.52,0.71-6.36,2.78-6.6,7.04c-0.62,10.73-1.44,21.45-1.96,32.18
C128.78,214.41,128.99,219.97,128.99,225.13z"
            />
            <path
              d="M272.95,106.28c-29.88,0-59.77,0.01-89.65-0.01c-6.75-0.01-10.38-5.87-7.41-11.76c1.74-3.43,4.73-3.94,8.18-3.93
c31.21,0.05,62.42,0.03,93.63,0.03c28.27,0,56.53-0.01,84.8,0.01c5.8,0,8.82,2.84,8.74,8.03c-0.07,5.14-2.83,7.62-8.63,7.63
C332.72,106.29,302.83,106.28,272.95,106.28z"
            />
            <path
              d="M364.14,227.32c-6.77,0.01-13.53,0.05-20.3-0.01c-5.68-0.05-8.73-2.8-8.69-7.66c0.04-5.17,2.78-7.9,8.43-7.94
c13.97-0.09,27.94-0.09,41.92-0.02c4.83,0.03,7.76,3.04,7.84,7.66c0.08,4.8-2.91,7.86-8.02,7.94
C378.26,227.39,371.2,227.32,364.14,227.32z"
            />
            <path
              d="M364.58,275.21c-7.06,0-14.12,0.06-21.18-0.02c-5.18-0.06-8.4-3.05-8.43-7.59c-0.03-4.63,3.01-7.86,8.17-7.91
c14.26-0.15,28.53-0.14,42.79,0.01c4.73,0.05,7.56,3.44,7.39,8.07c-0.17,4.48-2.96,7.35-7.56,7.41
C378.7,275.28,371.64,275.21,364.58,275.21z"
            />
            <path
              d="M364.35,180.67c-6.91,0-13.83,0.05-20.74-0.01c-5.43-0.05-8.71-3.08-8.66-7.81c0.05-4.58,3.15-7.65,8.39-7.69
c13.97-0.12,27.95-0.1,41.93,0.01c5.25,0.04,8.13,3.04,8.08,7.84c-0.06,4.79-3,7.6-8.24,7.65
C378.18,180.72,371.27,180.67,364.35,180.67z"
            />
            <path
              d="M364.6,306.23c6.77,0,13.54-0.05,20.3,0.01c5.43,0.05,8.51,3.01,8.43,7.95c-0.08,4.75-3.07,7.65-8.3,7.68
c-13.68,0.07-27.37,0.07-41.05,0c-5.84-0.03-9.07-3.02-8.98-7.99c0.09-4.77,3.26-7.58,8.84-7.64
C350.77,306.19,357.68,306.24,364.6,306.23z"
            />
          </g>
        </g>
      </svg>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/**
    * component style 
  */
.variants {
  .svg-icon {
    width: 22px !important;
  }
}
</style>
